import styled from 'styled-components';
import {
  gridGap,
  GridGapProps,
  gridAutoFlow,
  gridColumn,
  gridTemplateColumns,
  gridTemplateAreas,
  gridAutoColumns,
  gridAutoRows,
  alignItems,
  // @ts-ignore
  justifyItems,
  justifyContent,
  GridAutoFlowProps,
  GridColumnProps,
  GridTemplateColumnsProps,
  GridTemplateAreasProps,
  GridAutoColumnsProps,
  GridAutoRowsProps,
  AlignItemsProps,
  JustifyItemsProps,
  JustifyContentProps,
} from 'styled-system';
import Box, { BoxProps } from '../box';
import Item from './Item';
import React from 'react';

export type GridProps = BoxProps &
  GridGapProps &
  GridColumnProps &
  GridAutoFlowProps &
  GridTemplateColumnsProps &
  GridTemplateAreasProps &
  GridAutoColumnsProps &
  GridAutoRowsProps &
  AlignItemsProps &
  JustifyItemsProps &
  JustifyContentProps;

const GridComponent = styled(Box) <GridProps>`
  display: grid;
  ${gridGap};
  ${gridColumn}
  ${gridAutoFlow};
  ${gridTemplateColumns};
  ${gridTemplateAreas};
  ${gridAutoColumns};
  ${gridAutoRows};
  ${alignItems};
  ${justifyContent};
  ${justifyItems};
`;

class Grid extends React.Component<GridProps> {
  public static Item = Item;
  public render() {
    let p = this.props;
    return (<GridComponent {...p}></GridComponent>);
  }
}

export default Grid;
