import Box from '../box/Box';
import Flex from '../flex/Flex';
import Image from '../image/Image';
import React, { FC } from 'react';
import Rating, { RatingProps } from '../rating/Rating';

const HVerifiedCheck: FC<any> = props => (
  <svg
    width={props.width || 21}
    height={props.height || 20}
    viewBox="0 0 21 20"
    fill="none"
    {...props}
  >
    <path
      d="M8 .5a2.6 2.6 0 00-2.413 1.63 2.6 2.6 0 00-3.381 3.428 2.6 2.6 0 000 4.884 2.6 2.6 0 003.38 3.428 2.6 2.6 0 004.845-.047 2.6 2.6 0 002.934-.52l-.321-.321.321.321a2.6 2.6 0 00.534-2.902 2.6 2.6 0 000-4.802 2.6 2.6 0 00-3.468-3.423A2.6 2.6 0 008 .5z"
      fill="url(#prefix__paint0_linear)"
    />
    <path
      d="M8 .5a2.6 2.6 0 00-2.413 1.63 2.6 2.6 0 00-3.381 3.428 2.6 2.6 0 000 4.884 2.6 2.6 0 003.38 3.428 2.6 2.6 0 004.845-.047 2.6 2.6 0 002.934-.52l-.321-.321.321.321a2.6 2.6 0 00.534-2.902 2.6 2.6 0 000-4.802 2.6 2.6 0 00-3.468-3.423A2.6 2.6 0 008 .5z"
      fill="url(#prefix__paint1_radial)"
      fillOpacity={0.2}
    />
    <path
      d="M8 .5a2.6 2.6 0 00-2.413 1.63 2.6 2.6 0 00-3.381 3.428 2.6 2.6 0 000 4.884 2.6 2.6 0 003.38 3.428 2.6 2.6 0 004.845-.047 2.6 2.6 0 002.934-.52l-.321-.321.321.321a2.6 2.6 0 00.534-2.902 2.6 2.6 0 000-4.802 2.6 2.6 0 00-3.468-3.423A2.6 2.6 0 008 .5z"
      stroke="url(#prefix__paint2_linear)"
      strokeLinejoin="round"
    />
    <g filter="url(#prefix__filter0_d)">
      <path
        d="M11 5.6l-4.2 4.8L5 8.6"
        stroke="#FEFEFE"
        strokeWidth={1.2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <linearGradient
        id="prefix__paint0_linear"
        x1={2.75}
        y1={-12.854}
        x2={16.607}
        y2={0.987}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.146} stopColor="#A0C192" />
        <stop offset={1} stopColor="#6BA8BF" />
      </linearGradient>
      <linearGradient
        id="prefix__paint2_linear"
        x1={2.75}
        y1={-12.854}
        x2={16.607}
        y2={0.987}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.146} stopColor="#A0C192" />
        <stop offset={1} stopColor="#6BA8BF" />
      </linearGradient>
      <radialGradient
        id="prefix__paint1_radial"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="rotate(90 0 8) scale(6.99988)"
      >
        <stop stopColor="#fff" stopOpacity={0.4} />
        <stop offset={1} stopColor="#6BA8BF" stopOpacity={0} />
      </radialGradient>
      <filter
        id="prefix__filter0_d"
        x={4.4}
        y={5}
        width={7.5}
        height={6.3}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dx={0.2} dy={0.2} />
        <feGaussianBlur stdDeviation={0.05} />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
      </filter>
    </defs>
  </svg>
);

interface AvatarProps {
  src?: string;
  size?: 'small' | 'medium' | 'large' | number;
  className?: string;
  ratingProps?: RatingProps;
  verifiedIconProps?: {
    width?: number;
    height?: number;
  };
  verified?: boolean;
  risingStar?: boolean;
  showRating?: boolean;
}

const Avatar: FC<AvatarProps> = ({
  verified,
  verifiedIconProps,
  ratingProps,
  showRating,
  src,
  className,
  size,
}) => {
  let width = 60;
  if (typeof size === 'number') {
    width = size;
  } else if (size === 'small') {
    width = 30;
  } else if (size === 'medium') {
    width = 45;
  } else if (size === 'large') {
    width = 60;
  }
  const avatarImageContainer = (
    <Box position="relative" borderRadius="50%" maxWidth={width}>
      {verified && (
        <Box position="absolute" top="0" right="0">
          <HVerifiedCheck {...verifiedIconProps} />
        </Box>
      )}
      <Image
        borderRadius="50%"
        width={width}
        height={width}
        src={src}
        className={className}
      />
    </Box>
  );

  if (showRating) {
    return (
      <Flex mt={1} flexDirection="column" alignItems="center" maxWidth={width}>
        <Box mb={1}>{avatarImageContainer}</Box>
        <Rating {...ratingProps} />
      </Flex>
    );
  }

  return avatarImageContainer;
};

export default Avatar;
