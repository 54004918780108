import { useApolloClient } from '@apollo/react-hooks';
import { DatePicker, Radio } from 'antd';
import get from 'lodash.get';
import moment from 'moment';
import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import {
  Box,
  Button,
  // CloseButton,
  Container,
  Flex,
  Icon,
  Input,
  Textarea,
  // OutlineButton,
  Typography,
} from '@thehhub/core-components';
import Editor from '@thehhub/wysiwyg';
import Form from '@thehhub/form';
import {
  determineFileIcon,
  jobStyleDefaults,
  removeTypename,
} from '@thehhub/web-utils';
import BudgetRangeInputs from '../budget-range-inputs';
import InputList from '../input-list';
import LocationSelect from '../location-select';
import MoodBoardStep from '../moodboard-form';

interface PostAJobFormProps {
  form: any;
  layout?: any;
  jobPost?: any;
  header?: React.ReactNode;
  onSuccess?: (jobPostInput: any) => void;
  submitting?: boolean;
  handleS3Upload: (file: File, apolloClient: any) => Promise<any>;
}

const Label = styled(Typography)`
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 18px;
`;

// const StyledRadioBox = styled(RadioBox)`
//   margin-right: 9px;
// `;

// const StyledCheckbox = styled(Checkbox)`
//   width: 50%;
// `;

const PostJobForm: React.FC<PostAJobFormProps> = ({
  form,
  // layout,
  jobPost,
  handleS3Upload,
  header,
  onSuccess,
  submitting,
}) => {
  // console.log(jobPost);
  // const apolloClient = useApolloClient();
  //
  // const [isUploading, setIsUploading] = useState(false);
  const apolloClient = useApolloClient();
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: acceptedFiles => {
      Promise.all(acceptedFiles.map(file => handleS3Upload(file, apolloClient)))
        .then(uploadedFiles => {
          return uploadedFiles.map(file => ({
            key: file?.key,
            name: file?.name,
            mimeType: file?.mimeType,
            size: file?.size,
          }));
        })
        .then(assembledFiles => {
          setFiles((prev: any[]) => prev.concat(assembledFiles));
          // setPayload(prev => ({
          //   ...prev,
          //   details: {
          //     ...(prev?.details ?? {}),
          //     files: assembledFiles,
          //   },
          // }));
        });
    },
  });

  const [moodboard, setMoodboard] = useState(
    get(jobPost, 'details.moodBoard', []) || []
  );
  const [files, setFiles] = useState(
    (get(jobPost, 'details.files', []) || []).map(
      (file: {
        key: any;
        name: any;
        extension: any;
        mimeType: any;
        size: any;
      }) => ({
        key: file.key,
        name: file.name,
        ...(file.extension ? { extension: file.extension } : {}),
        ...(file.mimeType ? { mimeType: file.mimeType } : {}),
        ...(file.size ? { size: file.size } : {}),
      })
    )
  );
  const { getFieldDecorator, validateFieldsAndScroll } = form;

  const budgetRange = get(jobPost, 'details.budget.range')
    ? removeTypename(get(jobPost, 'details.budget.range'))
    : null;
  const budgetRangeMin = budgetRange.min;
  const budgetRangeMax = budgetRange.max;

  const scheduleDueDate = get(jobPost, 'details.schedule.dueDate');
  const dueDate = scheduleDueDate
    ? moment(new Date(parseInt(scheduleDueDate, 10)))
    : '';

  // const handleFileDrop = files => {
  //   setIsUploading(true);
  //   Promise.all(files.map(file => handleS3Upload(file, apolloClient))).then(
  //     uploadedFiles => {
  //       setFiles(prev => prev.concat(uploadedFiles));
  //       setIsUploading(false);
  //     }
  //   );
  // };
  //
  // const handleImageDrop = files => {
  //   setIsUploading(true);
  //   Promise.all(files.map(file => handleCloudinaryUpload(file))).then(
  //     uploadedFiles => {
  //       setMoodboard(prev => prev.concat(uploadedFiles));
  //       setIsUploading(false);
  //     }
  //   );
  // };

  // const jobPostStyles = jobStyleTranslator(
  //   get(jobPost, 'details.styles', []) || []
  // ).map(styleGroup => JSON.stringify(styleGroup.value));
  // console.log(jobPostStyles);
  // console.log(jobPostStyles);
  const removeFile = (index: number) => () =>
    setFiles((prev: any[]) => prev.filter((_file, i) => i !== index));

  const radioStyle: any = {
    height: '30px',
    lineHeight: '30px',
    float: 'left',
    width: '45%',
  };
  return (
    <Box
      as="form"
      onSubmit={(e: any) => {
        e.preventDefault();
        validateFieldsAndScroll((errs: any, values: any) => {
          if (errs || submitting) {
            return;
          }
          const budgetRange = get(values, 'details.budget.range');
          const budget = {
            budget: {
              range: {
                min: budgetRange.min * 100,
                max: budgetRange.max * 100,
              },
            },
          };
          let builtStyles = [];
          if (get(values, 'details.styles')) {
            // @ts-ignore
            // console.log(values.details.styles)
            // const styleString = JSON.parse(get(values, 'details.styles'));
            // builtStyles = styleString;
            // const styles = [];
            // styleStrings.map(item =>
            //   // @ts-ignore
            //   JSON.parse(item).forEach(style => styles.push(style))
            // );

            builtStyles = JSON.parse(get(values, 'details.styles'));
          }

          if (onSuccess) {
            onSuccess({
              ...values,
              details: {
                ...(values.details || {}),
                ...(values.guidelines || {}),
                files,
                ...budget,
                styles: builtStyles,
                moodBoard: removeTypename(moodboard),
              },
            });
          }
        });
      }}
      mt={header ? '72px' : 0}
    >
      {header}
      <Box px={3}>
        <Container id="select-style-step">
          <Typography fontSize="18px" bold>
            Select main style of creator{' '}
          </Typography>
          <Typography mt={2} mb={3} fontSize="16px" color="#6F6F6F">
            If you’re looking for a style that isn’t on the list, select the
            next best option, you can always add this detail in your job
            description.
          </Typography>

          <Form.Item>
            {getFieldDecorator('details.styles', {
              initialValue: JSON.stringify(get(jobPost, 'details.styles', [])),
              rules: [
                {
                  required: true,
                  message: 'Please select at least one main style.',
                },
              ],
              // onChange: styles => {
              // console.log(styles);
              // setPayload(prev => merge(prev, { details: { styles } }));
              // },
            })(
              <Radio.Group
              // boxProps={{
              //   width: [1, 1, 1, 1 / 2],
              //   mr: 0,
              //   mb: 1,
              // }}
              >
                {(jobStyleDefaults || []).map((style, i) => (
                  <Radio
                    style={radioStyle}
                    key={i}
                    value={JSON.stringify(style.value)}
                    // width={[1 / 2]}
                    // display="block"
                  >
                    {style.label}
                  </Radio>
                ))}
              </Radio.Group>
            )}
          </Form.Item>
        </Container>

        {/*<Form.Item*/}
        {/*  layout={layout}*/}
        {/*  label={*/}
        {/*    <Label>*/}
        {/*      Creator Type<Typography.span color="red">*</Typography.span>*/}
        {/*    </Label>*/}
        {/*  }*/}
        {/*  mb={4}*/}
        {/*>*/}
        {/*  {getFieldDecorator('skill', {*/}
        {/*    initialValue: get(jobPost, 'skill', 'PHOTOGRAPHER'),*/}
        {/*    rules: [*/}
        {/*      {*/}
        {/*        required: true,*/}
        {/*        message: 'Please select a creator type.',*/}
        {/*      },*/}
        {/*    ],*/}
        {/*  })(*/}
        {/*    <Radio.Group boxProps={{ mr: 3 }}>*/}
        {/*      {skills.map(skill => (*/}
        {/*        <StyledRadioBox*/}
        {/*          key={skill.value}*/}
        {/*          value={skill.value}*/}
        {/*          disabled={skill.value !== 'PHOTOGRAPHER'}*/}
        {/*          externalLabel={skill.label}*/}
        {/*          style={{ marginRight: 20 }}*/}
        {/*        >*/}
        {/*          <Flex flexDirection="column">*/}
        {/*            <Image src={skill.illustration} />*/}
        {/*            {skill.value !== 'PHOTOGRAPHER' && (*/}
        {/*              <Typography color="gray" mt={2} fontSize="10px">*/}
        {/*                Coming Soon*/}
        {/*              </Typography>*/}
        {/*            )}*/}
        {/*          </Flex>*/}
        {/*        </StyledRadioBox>*/}
        {/*      ))}*/}
        {/*    </Radio.Group>*/}
        {/*  )}*/}
        {/*</Form.Item>*/}

        <Form.Item
          // layout={layout}
          label={
            <Label>
              What’s your job?
              <Typography as="span" color="red">
                *
              </Typography>
            </Label>
          }
          mb={4}
        >
          {getFieldDecorator('title', {
            initialValue: get(jobPost, 'title', ''),
            rules: [
              {
                required: true,
                message: "Whoops, don't forget to provide a job title!",
              },
            ],
          })(
            <Input
              size="large"
              data-test-id="title"
              placeholder="Enter a job title"
              aria-describedby="job-name-help"
            />
          )}
        </Form.Item>
        <Form.Item
          // layout={layout}
          label={
            <Label>
              Describe what you need done.
              <Typography as="span" color="red">
                *
              </Typography>
            </Label>
          }
          mb={4}
        >
          {getFieldDecorator('description', {
            initialValue: get(jobPost, 'description', ''),
            rules: [
              {
                required: true,
                message: 'Please enter a description.',
              },
            ],
          })(<Editor placeholder="Enter a brief overview of your job" />)}
        </Form.Item>
        <Box mb={4}>
          <Label>Already have a job brief or other relevant files?</Label>
          <span {...getRootProps()}>
            <input type="file" {...getInputProps()} />
            <Button variant="outline" type="button" icon="Upload">
              Upload
            </Button>
          </span>
          {/*<Dropzone*/}
          {/*  m={0}*/}
          {/*  height={100}*/}
          {/*  isUploading={isUploading}*/}
          {/*  options={{*/}
          {/*    onDrop: handleFileDrop,*/}
          {/*  }}*/}
          {/*/>*/}
        </Box>

        <Box mb={4}>
          {files.map(
            (
              file: {
                key: string | number | undefined;
                mimeType: any;
                type: any;
                name: any;
              },
              index: number
            ) => (
              <Flex key={file.key} mb={2} alignItems="center">
                <Icon
                  name={determineFileIcon(file.mimeType || file.type)}
                  size={24}
                  mr={2}
                />
                <Typography mr={3}>{file.name}</Typography>
                <Button onClick={removeFile(index)}>
                  <Icon name="Delete" />
                </Button>
              </Flex>
            )
          )}
        </Box>
        <Flex flexDirection={['column', 'column', 'row']}>
          <Box width={[1, 1, 1 / 2]} mr={[0, 0, 2]}>
            <Form.Item label={<Label>Location</Label>}>
              {getFieldDecorator('details.location', {
                initialValue: jobPost
                  ? get(removeTypename(jobPost), 'details.location', {})
                  : null,
                rules: [
                  {
                    validator: (
                      _rule: any,
                      value: { locality: any; region: any; country: any },
                      callback: (arg0: string | undefined) => void
                    ) => {
                      if (!value) {
                        callback(undefined);
                      } else {
                        if (
                          !value.locality ||
                          !value.region ||
                          !value.country
                        ) {
                          callback('Please enter a more specific location');
                        } else {
                          callback(undefined);
                        }
                      }
                    },
                  },
                ],
              })(<LocationSelect size="large" style={{ width: '100%' }} />)}
            </Form.Item>
          </Box>
          <Box width={[1, 1, 1 / 2]} ml={[0, 0, 2]}>
            <Form.Item label={<Label>Due date</Label>}>
              {getFieldDecorator('details.schedule.dueDate', {
                validateTrigger: 'onBlur',
                ...(dueDate
                  ? {
                      initialValue: dueDate,
                    }
                  : {}),
                // rules: [
                //   {
                //     validator: (field, value, callback) => {
                //       if (!value) {
                //         // only validate if value populated
                //         return callback();
                //       }
                //       const parsed = moment(new Date(value));
                //       if (parsed.isSameOrBefore(Date.now())) {
                //         return callback('Must be a future date.');
                //       }
                //       return callback();
                //     },
                //   },
                // ],
              })(
                <DatePicker
                  size="large"
                  style={{ width: '100%' }}
                  format="MM/DD/YYYY"
                  // @ts-ignore
                  disabledDate={current => current && current < moment()}
                  showToday={false}
                  // calendarProps={{
                  //   disabledDate: current => current && current < moment(),
                  //   showToday: false,
                  // }}
                  // inputProps={{
                  //   size: 'large',
                  //   prefix: <Icon name="DatePicker" size="1em" />,
                  // }}
                />
                // <Input
                //   size="large"
                //   placeholder="MM/DD/YYYY"
                //   prefix={<Icon name="DatePicker" size="1em" />}
                //   cleaveOptions={{
                //     date: true,
                //   }}
                // />
              )}
            </Form.Item>
          </Box>
        </Flex>

        <Form.Item
          // layout={layout}
          label={
            <>
              <Typography fontSize="18px" bold mb={3}>
                What’s your job budget?
                <Typography as="span" color="red">
                  *
                </Typography>
              </Typography>
              {/*<Typography color="#6F6F6F" mb={3}>*/}
              {/*  The normal budget price is $550 - $600*/}
              {/*</Typography>*/}
            </>
          }
          mb={4}
        >
          {getFieldDecorator('details.budget.range', {
            initialValue: {
              min: budgetRangeMin / 100 || 0,
              max: budgetRangeMax / 100 || 10000,
            },
            rules: [
              {
                required: true,
                message: 'Please select a budget range',
              },
            ],
          })(<BudgetRangeInputs />)}
        </Form.Item>

        {/*<Form.Item mb={4} label={<Label>Styles</Label>}>*/}
        {/*  {getFieldDecorator('details.styles', {*/}
        {/*    initialValue: get(jobPost, 'details.styles', []),*/}
        {/*  })(*/}
        {/*    <Checkbox.Group*/}
        {/*      boxProps={{*/}
        {/*        width: [1, 1, 1, 1 / 2],*/}
        {/*        mr: 0,*/}
        {/*        mb: 1,*/}
        {/*      }}*/}
        {/*    >*/}
        {/*      {(styles || []).map((style, i) => (*/}
        {/*        <StyledCheckbox*/}
        {/*          key={i}*/}
        {/*          value={style.value}*/}
        {/*          width={[1 / 2]}*/}
        {/*          display="block"*/}
        {/*        >*/}
        {/*          {style.label}*/}
        {/*        </StyledCheckbox>*/}
        {/*      ))}*/}
        {/*    </Checkbox.Group>*/}
        {/*  )}*/}
        {/*</Form.Item>*/}

        <Label>Guidelines</Label>
        <Typography mb={1}>
          <strong>Do's</strong>
        </Typography>
        <Form.Item
        // layout={layout}
        >
          <Box mb={3}>
            {getFieldDecorator('details.guidelines.do', {
              initialValue: get(jobPost, 'details.guidelines.do', []),
            })(<InputList placeholder="Enter a do" />)}
            <Typography mt={2}>e.g., "Pay attention to detail."</Typography>
          </Box>
        </Form.Item>
        {/* <Divider my={3} /> */}
        <Typography mb={1} mt={3}>
          <strong>Dont's</strong>
        </Typography>
        <Form.Item mb={4}>
          <Box>
            {getFieldDecorator('details.guidelines.dont', {
              initialValue: get(jobPost, 'details.guidelines.dont', []),
            })(<InputList placeholder="Enter a dont" />)}
            <Typography mt={2}>e.g., "Don't be an ass."</Typography>
          </Box>
        </Form.Item>

        {/*<Box mb={4}>*/}
        {/*  <Label>Moodboard</Label>*/}
        {/*  <Dropzone*/}
        {/*    m={0}*/}
        {/*    height={100}*/}
        {/*    isUploading={isUploading}*/}
        {/*    options={{*/}
        {/*      accept: 'image/*',*/}
        {/*      onDrop: handleImageDrop,*/}
        {/*    }}*/}
        {/*  />*/}
        {/*  <Box mt={3}>*/}
        {/*    <ImageModalGrid*/}
        {/*      showRemove*/}
        {/*      onRemove={index =>*/}
        {/*        setMoodboard(images => images.filter((img, i) => i !== index))*/}
        {/*      }*/}
        {/*      size={110}*/}
        {/*      images={moodboard.map(cloudinaryFile => ({*/}
        {/*        publicId: cloudinaryFile.public_id,*/}
        {/*        caption: '',*/}
        {/*      }))}*/}
        {/*    />*/}
        {/*  </Box>*/}
        {/*</Box>*/}

        <Box mt={3} mb={4} mx={-3}>
          <MoodBoardStep
            payload={jobPost}
            textAlign="left"
            setPayload={(
              func: (arg0: any) => { details: { moodBoard: never[] } }
            ) => {
              const update = func(get(jobPost, 'details.moodBoard', [])) || {
                details: {
                  moodBoard: [],
                },
              };
              // console.log(update)

              // console.log(
              //   'update',
              //   update.details.moodBoard
              //     .filter(publicId => !!publicId)
              //     .map(publicId => ({ publicId, type: 'IMAGE' }))
              // );
              setMoodboard(update.details.moodBoard || []);
            }}
          />
        </Box>

        <Box>
          <Label>Usage Rights</Label>
          <Form.Item m={0}>
            {getFieldDecorator('details.usage.type', {
              initialValue: get(jobPost, 'details.usage.type'),
            })(
              <Radio.Group>
                <Radio
                  style={{
                    marginBottom: 18,
                    color: '#6F6F6F',
                    display: 'block',
                  }}
                  value="UNLIMITED"
                >
                  Unlimited rights
                </Radio>
                <Radio
                  style={{
                    marginBottom: 18,
                    color: '#6F6F6F',
                    display: 'block',
                  }}
                  value="DIGITAL"
                >
                  Digital Only
                </Radio>
                <Radio
                  style={{
                    marginBottom: 18,
                    color: '#6F6F6F',
                    display: 'block',
                  }}
                  value="OTHER"
                >
                  Other (Please Specify)
                </Radio>
              </Radio.Group>
            )}
          </Form.Item>

          <Form.Item style={{ margin: 0 }}>
            {getFieldDecorator('details.usage.description', {
              initialValue: get(jobPost, 'details.usage.description'),
              rules: [
                {
                  required:
                    form.getFieldValue('details.usage').type === 'OTHER',
                  message: 'Please enter usage rights for "OTHER"',
                },
              ],
            })(
              <Textarea
                disabled={form.getFieldValue('details.usage').type !== 'OTHER'}
                placeholder="Enter the details for usage rights"
              />
            )}
          </Form.Item>
        </Box>

        <Form.Item label={' '}>
          <Box>
            <Button
              size="large"
              disabled={submitting}
              loading={submitting}
              type="submit"
              // variant="primaryLarge"
              width="100%"
            >
              {jobPost ? 'Save Changes' : 'Post Job'}
            </Button>
          </Box>
        </Form.Item>
      </Box>
    </Box>
  );
};

PostJobForm.defaultProps = {
  layout: 'vertical',
};

export default Form.create<PostAJobFormProps>()(PostJobForm);
