import styled from 'styled-components';
import {
  gridColumn,
  gridRow,
  gridArea,
  GridColumnProps,
  GridRowProps,
  GridAreaProps,
} from 'styled-system';
import Box, { BoxProps } from '../box';

export type ItemProps = BoxProps &
  GridColumnProps &
  GridRowProps &
  GridAreaProps;

const Item = styled(Box)<ItemProps>`
  ${gridColumn};
  ${gridRow};
  ${gridArea};
`;

export default Item;
