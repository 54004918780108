import { css } from 'styled-components';

export const bordersStyle = ({ color, theme, error }: any) => {
  const borderColor = !error
    ? typeof color === 'string'
      ? theme.colors[color]
      : theme.colors.borderGray
    : theme.colors.red;
  const focusColor = !error
    ? borderColor === theme.colors.borderGray
      ? theme.colors.blue
      : borderColor
    : theme.colors.red;
  return {
    'border-width': '1px',
    'border-color': `${borderColor}`,
    'box-shadow': `0 0 0 1px ${borderColor}33`,
    ':focus': {
      outline: 0,
      'border-color': `${focusColor}`,
      'box-shadow': `0 0 0 2px ${focusColor}33`,
    },
  };
};

export const INPUT_HEIGHT = 35;

export const inputStyles = ({ theme }: any) => css`
  position: relative;
  -webkit-appearance: none;
  color: ${theme.colors.grayDark};
  border: 1px solid ${theme.colors.borderGray};
  font-family: ${theme.font};
  font-size: 15px;
  height: ${INPUT_HEIGHT}px;
  transition: border 300ms ease-in, box-shadow 0.3s ease-in;
  width: 100%;
  background: ${theme.colors.white};
  padding-left: 8px;
  box-shadow: 0 0 0 2px rgba(107, 168, 191, 00);

  border-radius: ${({ suffix }: any) =>
    suffix
      ? `0 0 ${theme.radii[2]}px ${theme.radii[2]}px`
      : `${theme.radii[2]}px`};

  &--search {
    border: 1px solid ${theme.colors.borderGray};
    border-radius: 5px;
    padding: 6px;
    line-height: 1.1;

    &:focus {
      border-color: ${theme.colors.blue};
      outline: none;
    }
  }

  &:focus {
    outline: none;
    border-color: #93bfcc;
    box-shadow: 0 0 0 2px rgba(107, 168, 191, 0.2);
  }

  &::placeholder {
    color: ${theme.colors.inputPlaceholderText};
  }

  &[disabled] {
    opacity: 0.7;
  }
`;
