import styled from 'styled-components';
import Box from '../box/Box';

export interface HidePropsBase {
  xs?: boolean;
  sm?: boolean;
  md?: boolean;
  lg?: boolean;
  xl?: boolean;
  xxl?: boolean;
  print?: boolean;
  theme?: any;
}

export type HideProps = HidePropsBase;

const getMaxWidth = (px: string) => parseInt(px, 10) - 1;

const breakpoints = (props: HideProps) => ({
  xs: `@media screen and (max-width: ${getMaxWidth(
    props.theme && props.theme.breakpoints[0]
  )}px)`,
  sm: `@media screen and (min-width: ${props.theme &&
    props.theme.breakpoints[0]}) and (max-width: ${getMaxWidth(
    props.theme && props.theme.breakpoints[1]
  )}px)`,
  md: `@media screen and (min-width: ${props.theme &&
    props.theme.breakpoints[1]}) and (max-width: ${getMaxWidth(
    props.theme && props.theme.breakpoints[2]
  )}px)`,
  lg: `@media screen and (min-width: ${props.theme &&
    props.theme.breakpoints[2]}) and (max-width: ${getMaxWidth(
    props.theme && props.theme.breakpoints[3]
  )}px)`,
  xl: `@media screen and (min-width: ${props.theme &&
    props.theme.breakpoints[3]})`,
  xxl: `@media screen and (min-width: ${props.theme &&
    props.theme.breakpoints[4]})`,
  print: `@media print`,
});

const hidden = (key: string) => (props: HideProps) =>
  // @ts-ignore
  props[key]
    ? {
        // @ts-ignore
        [breakpoints(props)[key]]: {
          display: 'none',
        },
      }
    : null;

const Hide = styled(Box)<HideProps>`
  ${hidden('xs')} 
  ${hidden('sm')} 
  ${hidden('md')} 
  ${hidden('lg')} 
  ${hidden('xl')} 
  ${hidden('xxl')} 
  ${hidden('print')};
`;

Hide.displayName = 'Hide';

export default Hide;
