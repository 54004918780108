import styled, { CSSProp } from 'styled-components';
import {
  background,
  BackgroundProps,
  border,
  BorderProps,
  color,
  ColorProps,
  flex,
  FlexProps,
  layout,
  LayoutProps,
  opacity,
  OpacityProps,
  position,
  PositionProps,
  shadow,
  ShadowProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
  variant,
} from 'styled-system';

export type TypographyComponentProps = BackgroundProps &
  BorderProps &
  ColorProps &
  FlexProps &
  LayoutProps &
  PositionProps &
  ShadowProps &
  SpaceProps &
  TypographyProps &
  OpacityProps & {
    reset?: boolean;
    italic?: boolean;
    semibold?: boolean;
    bold?: boolean;
    caps?: boolean;
    strikeThrough?: boolean;
    variant?:
      | 'title1'
      | 'title2'
      | 'title3'
      | 'title4'
      | 'large'
      | 'body1'
      | 'body2'
      | 'mini';
  } & { as?: any; css?: CSSProp | any } & { [key: string]: any };

const italic = (props: TypographyComponentProps): any =>
  props.italic ? { fontStyle: 'italic' } : null;

const bold = (props: TypographyComponentProps): any =>
  props.bold ? { fontWeight: 'bold' } : null;

const semibold = (props: TypographyComponentProps): any =>
  props.semibold ? { fontWeight: 700 } : null;

const caps = (props: TypographyComponentProps): any =>
  props.caps ? { textTransform: 'uppercase' } : null;

const strikeThrough = (props: TypographyComponentProps): any =>
  props.strikeThrough ? { textDecoration: 'line-through' } : null;

const titleCommons = {
  color: 'black',
  textTransform: 'uppercase',
  fontWeight: 'bold',
};

const Typography = styled.div<TypographyComponentProps>(
  {
    margin: 0,
  },
  bold,
  semibold,
  italic,
  caps,
  strikeThrough,
  () =>
    variant({
      variants: {
        title1: {
          ...titleCommons,
          fontSize: '72px',
          lineHeight: '98%',
          fontWeight: 900,
        },
        title2: {
          ...titleCommons,
          fontFamily: 'heading',
          fontSize: '64px',
          lineHeight: '100%',
        },
        title3: {
          ...titleCommons,
          fontFamily: 'heading',
          fontSize: '45px',
          lineHeight: '100%',
        },
        title4: {
          ...titleCommons,
          fontFamily: 'heading',
          fontSize: '24px',
          lineHeight: '100%',
        },
        large: {
          fontWeight: 'bold',
          fontSize: '24px',
          lineHeight: '29px',
          letterSpacing: '-0.015em',
        },
        body1: {
          fontSize: '21px',
          lineHeight: '150%',
        },
        body2: {
          fontSize: '14px',
          lineHeight: '150%',
        },
        mini: {
          ...titleCommons,
          fontSize: '12px',
          lineHeight: '15px',
        },
      },
    }),

  background,
  border,
  color,
  flex,
  layout,
  opacity,
  position,
  shadow,
  space,
  typography
);

Typography.displayName = 'Typography';

export default Typography;
