import styled, { CSSProp } from 'styled-components';
import {
  background,
  BackgroundProps,
  border,
  BorderProps,
  color,
  ColorProps,
  flex,
  FlexProps,
  layout,
  LayoutProps,
  opacity,
  OpacityProps,
  position,
  PositionProps,
  shadow,
  ShadowProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from 'styled-system';

export type BoxProps = BackgroundProps &
  BorderProps &
  ColorProps &
  FlexProps &
  LayoutProps &
  PositionProps &
  ShadowProps &
  SpaceProps &
  TypographyProps &
  OpacityProps & { as?: any; css?: CSSProp | any } & { [key: string]: any };

const Box = styled('div')<BoxProps>(
  background,
  border,
  color,
  flex,
  layout,
  opacity,
  position,
  shadow,
  space,
  typography
);

Box.displayName = 'Box';

export default Box;
