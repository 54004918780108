import styled from 'styled-components';
import { flex, layout, space } from 'styled-system';
import { bordersStyle, inputStyles } from '../input/styles';

const Textarea = styled.textarea`
  ${inputStyles};

  padding-top: 8px;
  min-height: 100px;
  ${bordersStyle}
  ${layout}
  ${space} 
  ${flex}
`;

export default Textarea;
