import { createGlobalStyle } from 'styled-components';

const BaseStyles = createGlobalStyle<{ theme: any }>`
  html {
    box-sizing: border-box;
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  body {
    margin: 0;
    
    font-family: ${props => props.theme.fonts.sans};
    line-height: ${props => props.theme.lineHeights.standard};
    font-weight: ${props => props.theme.fontWeights.medium};

    background: ${props => props.theme.colors.pageBackground};
    color: ${props => props.theme.colors.text};

    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .grecaptcha-badge {
    display: none !important;
  }

`;

export default BaseStyles;
