import styled, { CSSProp } from 'styled-components';
import {
  background,
  BackgroundProps,
  border,
  BorderProps,
  color,
  ColorProps,
  flex,
  FlexProps,
  flexbox,
  FlexboxProps,
  flexWrap,
  flexGrow,
  FlexWrapProps,
  FlexGrowProps,
  layout,
  LayoutProps,
  opacity,
  OpacityProps,
  position,
  PositionProps,
  shadow,
  ShadowProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from 'styled-system';

export type Props = BackgroundProps &
  BorderProps &
  ColorProps &
  FlexProps &
  FlexboxProps &
  FlexWrapProps &
  FlexGrowProps &
  LayoutProps &
  PositionProps &
  ShadowProps &
  SpaceProps &
  TypographyProps &
  OpacityProps & {
    centered?: boolean;
    wrap?: boolean;
    css?: CSSProp;
  };

const Flex = styled('div')<Props>(
  {
    display: 'flex',
  },
  background,
  border,
  color,
  flex,
  flexbox,
  layout,
  opacity,
  position,
  shadow,
  space,
  typography,
  flexWrap,
  flexGrow,

  props =>
    props.centered
      ? {
          alignItems: 'center',
          justifyContent: 'center',
        }
      : null,
  props =>
    props.wrap
      ? {
          flexWrap: 'wrap',
        }
      : null
);

Flex.displayName = 'Flex';

export default Flex;
