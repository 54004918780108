import styled from 'styled-components';
import {
  border,
  BorderProps,
  color,
  ColorProps,
  flexbox,
  FlexboxProps,
  layout,
  LayoutProps,
  SizeProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
  variant,
  width,
  WidthProps,
} from 'styled-system';

type ButtonSizes = 'small' | 'medium' | 'large';
type ButtonVariants = 'outline' | 'danger' | 'ghost' | 'ghostPrimary' | 'text';

type ButtonProps = ColorProps &
  WidthProps &
  SizeProps &
  FlexboxProps &
  LayoutProps &
  SpaceProps &
  BorderProps &
  TypographyProps & {
    icon?: string | null;
    href?: string | null;
    rel?: string | null;
    target?: string | null;
    size?: ButtonSizes;
    loading?: boolean;
    title?: string | null;
    shape?: 'circle' | 'default';
    variant?: ButtonVariants;
    theme?: any;
    ref?: any;
    css?: any;
  };

export const Button = styled.button<ButtonProps>(
  props => ({
    alignItems: 'center',
    appearance: 'none',
    borderRadius: `${props.theme ? props.theme.radii[2] : 5}px`,
    cursor: 'pointer',
    display: 'inline-flex',
    fontFamily: 'inherit',
    fontWeight: 'bold',
    justifyContent: 'center',
    lineHeight: 1,
    margin: 0,
    padding: '10px 18px',
    textAlign: 'center',
    textDecoration: 'none',
    textTransform: 'uppercase',
    outline: 'none',
    '& > *': {
      pointerEvents: 'none',
    },
    ...(props.loading
      ? {}
      : {
          '&:disabled': {
            cursor: 'default',
            color: '#00000059',
            backgroundColor: '#ebebeb',
            borderColor: '#ebebeb',
            '&:hover': {
              backgroundColor: '#ebebeb',
              borderColor: '#ebebeb',
            },
          },
        }),
  }),
  props => ({
    border: `1px solid ${props.theme ? props.theme.colors.blue : 'blue'}`,
    backgroundColor: props.theme ? props.theme.colors.blue : 'blue',
    color: props.theme ? props.theme.colors.white : 'white',
    '&:hover': {
      borderColor: '#90bdcf',
      backgroundColor: '#90bdcf',
      textDecoration: 'none',
    },
    '&:focus': {
      outline: 'none',
      backgroundColor: '#5295af',
      borderColor: '#5295af',
    },
    '&:active': {
      backgroundColor: '#5295af',
      borderColor: '#5295af',
    },
  }),
  props =>
    variant({
      prop: 'shape',
      variants: {
        circle: {
          // padding: 0,
          borderRadius: 9999,
          width: props.theme.buttonSizes.medium,
          height: props.theme.buttonSizes.medium,
        },
      },
    }),
  props =>
    variant({
      prop: 'size',
      variants: {
        small: {
          fontSize: `${props.theme.fontSizes[0]}px`,
          padding: '7px 16px',
        },
        medium: {
          fontSize: `${props.theme.fontSizes[1]}px`,
          padding: '10px 18px',
        },
        large: {
          fontSize: `${props.theme.fontSizes[2]}px`,
          padding: '14px 25px',
        },
      },
    }),
  props =>
    variant({
      variants: {
        outline: {
          backgroundColor: 'white',
          color: 'blue6',
          borderColor: 'blue6',
          '&:hover': {
            borderColor: 'blue6',
            backgroundColor: '#f1f6f8',
          },
          '&:focus': {
            backgroundColor: ' #f1f6f8',
            borderColor: '#467a87',
          },
          '&:active': {
            backgroundColor: 'white',
            borderColor: 'blue',
          },
        },
        danger: {
          backgroundColor: 'white',
          color: 'red',
          borderColor: 'red',
          '&:hover': {
            borderColor: 'red',
            backgroundColor: '#f9efef',
          },
          '&:focus': {
            backgroundColor: '#f9efef',
            borderColor: '#772c2c',
          },
          '&:active': {
            color: '#912622',
            backgroundColor: 'white',
            borderColor: '#902622',
          },
        },
        ghost: {
          backgroundColor: 'transparent',
          color: 'white',
          borderColor: 'white',
          '&:hover': {
            color: props.color || 'white',
            borderColor: 'white',
            backgroundColor: 'rgba(255, 255, 255, 0.2)',
          },
          '&:focus': {
            color: props.color || 'white',
            backgroundColor: 'transparent',
            borderColor: 'white',
          },
          '&:active': {
            color: props.color || '#b6ecff',
            backgroundColor: 'rgba(255, 255, 255, 0.2)',
            borderColor: '#b6ecff',
          },
        },
        ghostPrimary: {
          backgroundColor: 'white',
          color: props.color || 'blue',
          borderColor: 'white',
          '&:hover': {
            color: props.color || 'blue',
            borderColor: 'white',
            backgroundColor: '#fefefe',
            opacity: 0.7,
          },
          '&:focus': {
            color: props.color || 'blue',
            backgroundColor: 'white',
            borderColor: '#b6ecff',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.15)',
          },
          '&:active': {
            color: props.color || 'blue',
            backgroundColor: 'white',
            borderColor: '#5295af',
          },
        },
        text: {
          backgroundColor: 'transparent',
          color: props.color || 'blue',
          borderColor: 'transparent',
          paddingLeft: 0,
          paddingRight: 0,
          '&:hover': {
            color: props.color || 'blue7',
            borderColor: 'transparent',
            backgroundColor: 'transparent',
          },
          '&:focus': {
            color: props.color || 'blue7',
            backgroundColor: 'transparent',
            borderColor: 'transparent',
            boxShadow: 'none',
          },
          '&:active': {
            color: props.color || 'blue7',
            backgroundColor: 'transparent',
            borderColor: 'transparent',
          },
        },
      },
    }),
  border,
  flexbox,
  color,
  width,
  space,
  layout,
  typography
);

export default Button;
