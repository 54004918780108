import React, { FunctionComponent } from 'react';

const Logo: FunctionComponent<{ width?: number; height?: number }> = props => (
  <svg width={100} height={52} fill="none" viewBox="0 0 100 52" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M73.487 52H26.513C11.87 52 0 40.36 0 26S11.87 0 26.513 0h46.974C88.13 0 100 11.64 100 26S88.13 52 73.487 52z"
      fill="#C45955"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M69.305 27.894c0 2.868-1.29 4.323-3.837 4.323-2.474 0-3.836-1.473-3.836-4.146v-9.11h-1.603v9.177c0 3.483 2.025 5.564 5.417 5.564 3.42 0 5.462-2.164 5.462-5.785V18.96h-1.603v8.933zm7.379-7.425h2.776c2.276 0 3.43.82 3.43 2.438 0 1.596-1.332 2.55-3.566 2.55h-2.64v-4.988zm3.07 11.503h-3.07v-5.009h2.64c3.322 0 4.018 1.29 4.018 2.372 0 1.775-1.174 2.637-3.588 2.637zm2.691-5.904c1.421-.686 2.07-2.018 2.07-3.228 0-1.448-.65-3.879-5.01-3.879H75.06v14.52h4.67c3.328 0 5.237-1.503 5.237-4.123 0-1.571-.921-2.745-2.522-3.29z"
      fill="#fff"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M73.717 4.111H54.041l.002 21.278h-8.217v-6.514h-1.602v27.127h-17.94c-11.324 0-20.537-9.048-20.537-20.17 0-11.121 9.213-20.17 20.537-20.17H49.92v-1.55H26.283c-12.195 0-22.116 9.743-22.116 21.72 0 11.977 9.921 21.722 22.116 21.722h19.544V26.96h8.215v6.513h1.602V5.663h18.073c11.324 0 20.537 9.048 20.537 20.17 0 11.121-9.213 20.17-20.537 20.17H50.145v1.55h23.572c12.195 0 22.116-9.744 22.116-21.72 0-11.978-9.921-21.722-22.116-21.722z"
      fill="#fff"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.471 22.59h3.476v8.767h1.462v-8.768h3.475v-1.375h-8.413v1.375zm17.567 3h-5.33v-4.375h-1.463v10.141h1.462v-4.422h5.331v4.422H30.5V21.215h-1.462v4.375zm10.829-3.016v-1.36h-6.423v10.142h6.423v-1.359h-4.96V26.95h4.138v-1.342h-4.139v-3.033h4.961z"
      fill="#fff"
    />
  </svg>
);

export default Logo;
