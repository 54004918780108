import Flex from '../flex/Flex';
import Box from '../box/Box';
import React from 'react';
import styled, { withTheme } from 'styled-components';

const StarIcon = styled(({ pathFill, pathStroke, ...props }) => (
  <svg
    width={props.size || 12}
    height={props.size || 12}
    viewBox="0 0 12 12"
    fill="none"
    {...props}
  >
    <path
      d="M6.476 1.262a.5.5 0 00-.952 0l-1.01 3.11h-3.27a.5.5 0 00-.293.904l2.645 1.922-1.01 3.11a.5.5 0 00.769.558L6 8.945l2.645 1.921a.5.5 0 00.77-.559l-1.01-3.11 2.644-1.92a.5.5 0 00-.294-.905h-3.27l-1.01-3.11z"
      fill={pathFill}
      stroke={pathStroke}
      strokeLinejoin="round"
    />
  </svg>
))`
  margin-right: 2px;
`;

export type RatingProps = { percent?: number; theme?: any; size?: number };
const Rating: React.FC<RatingProps> = ({ percent, theme, size, ...rest }) => {
  // console.log(`${Math.round(percent * 100)}%`);
  return (
    <Box
      position="relative"
      height={size ? size * 2 : 24}
      width={size ? size * 5 : '60px'}
      {...rest}
    >
      <Box
        position="absolute"
        width={`${Math.round(percent! * 100)}%`}
        overflow="hidden"
        top="0"
        left="0"
        zIndex={1}
      >
        <Flex flexWrap="nowrap">
          {[0, 1, 2, 3, 4].map(i => (
            <Box width={size || '12px'} key={i}>
              <StarIcon
                size={size}
                pathFill={theme ? theme.colors.yellow : 'gold'}
                pathStroke={theme ? theme.colors.yellow : 'gold'}
              />
            </Box>
          ))}
        </Flex>
      </Box>
      <Box position="absolute" top="0" left="0">
        <Flex justifyContent="center">
          {[0, 1, 2, 3, 4].map(i => (
            <Box width={size || '12px'} key={i}>
              <StarIcon
                size={size}
                pathFill={theme ? theme.colors.white : 'white'}
                pathStroke={theme ? theme.colors.yellow : 'gold'}
              />
            </Box>
          ))}
        </Flex>
      </Box>
    </Box>
  );
};

export default withTheme(Rating);
