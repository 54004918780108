import { Typography } from '@thehhub/core-components';
import { removeTypename } from '@thehhub/web-utils';
import Select from '@thehhub/select';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useApolloClient } from '@apollo/react-hooks';
import gql from 'graphql-tag';

export interface GeolocationInput {
  lat: number;
  lon: number;
}

export interface LocationInput {
  locality?: string | null;
  region?: string | null;
  country?: string | null;
  postalCode?: string | null;
  coords?: GeolocationInput | null;
}

const LOCATION_PREDICTION_QUERY = gql`
  query LocationPredictionQuery($input: String!, $limit: Int) {
    locationPrediction(input: $input, limit: $limit) {
      id
      locality
      region
      country
      coords {
        lat
        lon
      }
      formatted
    }
  }
`;

type AntdSelectProps = import('antd/lib/select').SelectProps<LocationInput>;

export interface LocationSelectProps extends AntdSelectProps {
  locationQuery?: any;
  // value?: LatLon | any;
  // onChange?: (cords: { lat: number; lon: number }) => void;
}

const StyledSelect = styled(Select)`
  //&.ant-select-lg .ant-select-selection__rendered {
  //  margin-left: 9px;
  //}
`;
const logger = console.debug;

const LocationSelect: React.FC<LocationSelectProps> = React.forwardRef(
  ({ locationQuery, onChange, value, ...rest }, ref) => {
    const [predictions, setPredictions] = useState<any[]>([]);
    const [selectValue, setSelectValue] = useState([]);
    const client = useApolloClient();

    function lookup(input: any) {
      return client.query({
        query: locationQuery,
        variables: {
          input,
        },
        context: {
          clientName: 'public',
          debounceKey: 'citiesAutocomplete',
        },
      });
    }

    useEffect(() => {
      if (value) {
        // @ts-ignore
        let lat = value.lat;
        // @ts-ignore
        let lon = value.lon;
        // @ts-ignore
        if (value.coords) {
          // @ts-ignore
          lat = value.coords.lat;
          // @ts-ignore
          lon = value.coords.lon;
        }

        if (localStorage) {
          logger('get cached location');

          try {
            const rawSelectedValue = localStorage.getItem(
              JSON.stringify({ lat, lon })
            );
            if (rawSelectedValue) {
              const selectedValue = JSON.parse(rawSelectedValue);
              logger(selectedValue);
              setSelectValue(selectedValue.formatted);
            } else if (value?.locality) {
              setSelectValue(
                // @ts-ignore
                [value?.locality, value?.region, value?.country].join(', ')
              );
            }
          } catch (e) {
            console.error(e);
          }
        }
      }
    }, [value]);

    const handleChange = async (input: string) => {
      if (!input) {
        setPredictions([]);
        setSelectValue([]);
        if (onChange) {
          // @ts-ignore
          onChange(null);
        }

        return;
      }

      try {
        const { data } = await lookup(input);
        if (data && data.locationPrediction) {
          setPredictions(data.locationPrediction);
        }
      } catch (e) {
        console.error(e);
      }

      // if (services && services.autoCompleteService) {
      //   services.autoCompleteService.getQueryPredictions(
      //     { input, types: ['(cities)'] },
      //     (results: any, status: string) => {
      //       if (status === 'OK') {
      //         setPredictions(
      //           results.filter(result => result.types.indexOf('locality') !== -1)
      //         );
      //       }
      //     }
      //   );
      // }
    };

    const handleSelection = (selection: any) => {
      const selectedValue = predictions.find(
        prediction => prediction.formatted === selection
      );
      logger('predictions %o', predictions);

      setSelectValue(selection);
      setPredictions([]);

      if (!onChange || !selectedValue) {
        return;
      }
      // @ts-ignore
      const { id, formatted, ...locationStrict } = selectedValue;
      // @ts-ignore
      onChange(removeTypename(locationStrict));

      if (localStorage) {
        logger('setting local storage', selectedValue);
        localStorage.setItem(
          JSON.stringify(removeTypename(selectedValue?.coords ?? {})),
          JSON.stringify(removeTypename(selectedValue))
        );
      }
      // services.geocoderService.geocode(
      //   { placeId: selectedValue.place_id },
      //   (results: any[], status: string) => {
      //     if (status !== 'OK' || !results[0]) {
      //       return;
      //     }
      //     const { address_components } = results[0];
      //     const { lat, lng } = results[0].geometry.location;
      //     const location: any = {
      //       coords: {
      //         lat: lat(),
      //         lon: lng(),
      //       },
      //     };
      //
      //     address_components.forEach((item: any) => {
      //       // console.log(item);
      //       if (item.types.indexOf('locality') !== -1) {
      //         location.locality = item.long_name;
      //       }
      //       if (item.types.indexOf('administrative_area_level_1') !== -1) {
      //         location.region = item.short_name;
      //       }
      //       if (item.types.indexOf('country') !== -1) {
      //         location.country = item.short_name;
      //       }
      //     });
      //     if (!location.locality) {
      //       address_components.forEach((item: any) => {
      //         if (
      //           item.types.indexOf('sublocality') !== -1 ||
      //           item.types.indexOf('natural_feature') !== -1 ||
      //           item.types.indexOf('establishment') !== -1
      //         ) {
      //           location.locality = item.long_name;
      //         }
      //       });
      //     }
      //     // console.log(location);
      //
      //     onChange(location);
      //   }
      // );
    };

    const options = predictions.map(prediction => (
      // @ts-ignore
      <Select.Option key={prediction.id} value={prediction.formatted}>
        {prediction.formatted}
      </Select.Option>
    ));

    return (
      // @ts-ignore
      <StyledSelect
        allowClear
        autoClearSearchValue={false}
        placeholder="Enter location"
        showArrow={false}
        onSearch={handleChange}
        // dropdownMenuStyle={
        //   options.length > 0
        //     ? {
        //         paddingBottom: 37,
        //         background:
        //           'rgba(0, 0, 0, 0) url("/static/powered_by_google_on_white_hdpi.png") no-repeat scroll 10px 93% / 144px 18px',
        //       }
        //     : {}
        // }
        // @ts-ignore
        onChange={handleChange}
        onSelect={handleSelection}
        // @ts-ignore
        // {
        //...(selectValue ? {value: [selectValue]} : {})
        // }
        value={selectValue ? selectValue : ''}
        showSearch
        // size="large"
        ref={ref}
        // @ts-ignore
        notFoundContent={
          <Typography>{selectValue ? 'Search for a location' : ''}</Typography>
        }
        style={{ minWidth: 220 }}
        {...rest}
      >
        {options}
      </StyledSelect>
    );
  }
);

LocationSelect.defaultProps = {
  locationQuery: LOCATION_PREDICTION_QUERY,
};

export default LocationSelect;

// .rc-select-dropdown-menu {
//   padding-bottom: 20px;
//   background: url('/static/images/powered_by_google_on_white.png') bottom left no-repeat;
//   background-size: auto;
//   background-size: 144px 18px;
// }
