import styled from 'styled-components';
import { color, ColorProps, space, SpaceProps, background, BackgroundProps } from 'styled-system';

export type LinkProps = LinkPropsBase & ColorProps & SpaceProps & BackgroundProps;

export interface LinkPropsBase {
  href?: string;
  alt?: string;
  target?: string;
  theme?: any;
  to?: any;
  prefetch?: boolean;
  as?: any;
  css?: any;
}

const Link = styled('a')<LinkProps>`
  cursor: pointer;
  text-decoration: none;
  color: ${props => props.theme.colors.blue};
  ${color}
  ${space}
  ${background}
  
  &:hover {
    text-decoration: underline;
  }
`;

Link.displayName = 'Link';

export default Link;
