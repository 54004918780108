import styled from 'styled-components';
import { background, borderColor, color } from 'styled-system';
import Box from '../box/Box';

const Tag = styled(Box)`
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
  min-width: inherit;
  border-radius: 5px;
  font-size: 12px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 22px;
  border: 1px solid transparent;
  background-color: #d9d9d9;
  color: white;
  padding: 0 12px;
  ${borderColor}
  ${background}
  ${color}
`;

export default Tag;
