import React, { FunctionComponent } from 'react';

const LogoSmall: FunctionComponent<{
  width?: number;
  height?: number;
}> = props => (
  <svg width={100} height={100} viewBox="0 0 100 100" fill="none" {...props}>
    <path
      d="M49.999 99.997c27.613 0 49.998-22.385 49.998-49.998C99.997 22.385 77.612 0 50 0 22.385 0 0 22.385 0 49.999c0 27.613 22.385 49.998 49.999 49.998z"
      fill="#C25B56"
    />
    <path
      d="M85.126 21.623A45.331 45.331 0 0060.01 5.958l-1.496-.347v43.167H41.085V35.357h-2.442v55.829a42.934 42.934 0 01-21.99-14.503 42.245 42.245 0 01-9.35-26.69C7.288 26.451 26.45 7.29 50 7.29V4.844C25.102 4.844 4.847 25.102 4.847 50A45.173 45.173 0 0039.58 93.945l1.502.353V51.222h17.43v13.421h2.443V8.708A42.731 42.731 0 0192.714 50c0 22.628-17.68 41.36-40.252 42.64-.801.046-1.64.07-2.458.07v2.445c.863 0 1.74-.027 2.597-.075C76.463 93.725 95.156 73.924 95.156 50a45.25 45.25 0 00-10.03-28.378z"
      fill="#fff"
    />
  </svg>
);

export default LogoSmall;
