export default (APP_STAGE?: string) => {
  const APP_ENV = process.env.NODE_ENV;
  const IS_STAGING_ENV = APP_STAGE === 'staging';

  return {
    IS_STAGING_ENV,
    /**
     * Product application URL
     */
    get APP_URL() {
      // return '/app';
      if (process.env.PUBLIC_URL) {
        return process.env.PUBLIC_URL;
      }

      if (IS_STAGING_ENV) {
        return 'https://staging.thehhub.com/app';
      }
      return APP_ENV === 'production' || APP_STAGE === 'production'
        ? 'https://thehhub.com/app'
        : 'http://localhost:5001';
    },
    APP_STAGE: APP_STAGE,
    APPLE_APP_ID: 'WMK2P2JB8K.com.thehhub.app',
    AUTH_GUEST_EMAIL: 'guest@thehhub.com',
    AUTH_GUEST_PASSWORD: 'thehhub',
    get AWS_APPSYNC_GRAPHQL_APP_URL() {
      if (IS_STAGING_ENV || APP_ENV === 'development') {
        return 'https://staging.thehhub.com/graphql';
      }
      return 'https://thehhub.com/graphql';
    },
    get AWS_APPSYNC_GRAPHQL_APP_ADMIN_URL() {
      if (IS_STAGING_ENV || APP_ENV === 'development') {
        return 'https://staging.thehhub.com/graphql-admin';
      }
      return 'https://thehhub.com/graphql-admin';
    },
    get AWS_APPSYNC_GRAPHQL_PUBLIC_KEY() {
      if (IS_STAGING_ENV || APP_ENV === 'development') {
        return 's1WFO4psyQ5wYioBKGhOe98gwxJ2HcVZ9eiVmShS';
      }
      return 'VoqVfSQ5JX7WScF3E87aH6JAIZB5xcQl2DtgLZDU';
    },
    get AWS_APPSYNC_GRAPHQL_PUBLIC_URL() {
      if (IS_STAGING_ENV || APP_ENV === 'development') {
        return 'https://staging.thehhub.com/graphql-public';
      }
      return 'https://thehhub.com/graphql-public';
    },
    CLOUDINARY_API_UPLOAD_PRESET: 'ewxvv8jz',
    CLOUDINARY_API_UPLOAD_URL:
      'https://api.cloudinary.com/v1_1/dfifzuyto/image/upload',
    CLOUDINARY_CLOUD_NAME: 'dfifzuyto',
    CLOUDINARY_SDK_SCRIPT_URL: 'https://widget.cloudinary.com/global/all.js',
    COGNITO_FLOW_TYPE: 'USER_PASSWORD_AUTH',
    get COGNITO_IDENTITY_POOL_ID() {
      if (IS_STAGING_ENV || APP_ENV === 'development') {
        return 'us-east-1:5bd23d1f-aa87-4909-a56c-d36a6a79feab';
      }
      return 'us-east-1:c68e2476-fcf0-4f06-bd0c-18d292007fb6';
    },
    COGNITO_MANDATORY_SIGN_IN: false,
    COGNITO_REGION: 'us-east-1',
    get COGNITO_USER_POOL_ID() {
      if (IS_STAGING_ENV || APP_ENV === 'development') {
        return 'us-east-1_jyqBb2v3B';
      }
      return 'us-east-1_ADqtrHrPD';
    },
    get COGNITO_USER_POOL_WEB_ID() {
      if (IS_STAGING_ENV || APP_ENV === 'development') {
        return '2j12g3h6go8euee4lj5hqarmj8';
      }
      return '7p3q5iqfb6ol7dm6nd1olmsrqf';
    },
    GOOGLE_ANALYTICS_ID: '96921326-1',
    GOOGLE_MAPS_API_KEY: 'AIzaSyC7-S2WMd6FZFN-BdERgWpLTone_UmPBoQ',
    get HOST() {
      if (IS_STAGING_ENV) {
        return 'staging.thehhub.com';
      }
      return APP_ENV === 'production' ? 'thehhub.com' : 'localhost';
    },
    FILE_MAX_ATTACHMENT_SIZE: 10000000,
    FILE_UPLOAD_IMAGE_ACCEPT: 'image/jpeg, image/png',
    INSTAGRAM_AUTH_ENDPOINT: 'https://api.instagram.com/oauth/authorize',
    INSTAGRAM_CLIENT_ID: 'e708f7b02dcb42dbabf086326b8e75e6',
    get LOG_LEVEL() {
      if (APP_STAGE === 'production') {
        return 'ERROR';
      }
      return 'DEBUG';
    },
    get LOGROCKET_ID() {
      if (IS_STAGING_ENV) {
        return 'dvrpb0/the-h-hub-staging';
      }
      if (APP_STAGE === 'production') {
        return 'dvrpb0/the-h-hub-production';
      }
      return 'dvrpb0/the-h-hub-dev';
    },
    get SEGMENT_APP_ID() {
      if (IS_STAGING_ENV || APP_ENV === 'development') {
        return 'TUpEBlJZVJyFcZtIEWI1Q8uCZDPoOUA3';
      }
      return 'LH1qZ0n77ZytVffIAGXiDpLhIkOYGufW';
    },
    SENTRY_DSN: 'https://af3e8beddc0642f5852700102d98418b@sentry.io/1538652',
    get STREAM_API_KEY() {
      if (IS_STAGING_ENV || APP_ENV === 'development') {
        return 'bbfcbm2vufy9';
      }
      return 'ucafkgs8ehuj';
    },
    get STRIPE_API_KEY() {
      if (IS_STAGING_ENV || APP_ENV === 'development') {
        return 'pk_test_DSJjJtJgulSZ0R7MNPpOqyAF';
      }
      return 'pk_live_WdZl2ZKnJZoZF08HUIsNhfTI';
    },
    SUPPORT_LAYER_ID: 'cre-bf7aqi7jyqbxv1g',
    get WEB_URL() {
      if (IS_STAGING_ENV) {
        return 'https://staging.thehhub.com';
      }
      return APP_ENV === 'production'
        ? 'https://thehhub.com'
        : 'http://localhost:3000';
    },
    get WP_URL() {
      if (IS_STAGING_ENV || APP_ENV === 'development') {
        return 'https://staging.thehhub.com';
      }
      return 'https://thehhub.com';
    },
  };
};
