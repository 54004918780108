import React, { KeyboardEvent, ReactNode } from 'react';
import styled, { css } from 'styled-components';
import {
  BorderProps,
  flex,
  FlexProps,
  FontSizeProps,
  layout,
  LayoutProps,
  space,
  SpaceProps,
} from 'styled-system';
import Relative from '../relative';
import { bordersStyle, INPUT_HEIGHT, inputStyles } from './styles';
import Typography from '../typography';

export type InputProps = BorderProps &
  LayoutProps &
  FontSizeProps &
  SpaceProps &
  FlexProps &
  // NativeInputPropsWithoutSize &
  InputPropsBase;

interface InputPropsBase {
  size?: 'medium' | 'large';
  type?: string;
  isSearch?: boolean;
  error?: string | boolean | null;
  prefix?: any;
  suffix?: string | ReactNode | null;
  onPressEnter?: (e: any) => void;
  onChange?: (e: any) => void;
  value?: string;
  theme?: any;
  css?: any;
  [key: string]: any;
  Textarea?: ReactNode;
}

const sizeStyles = (props: InputProps) => {
  if (props.size! === 'large') {
    return {
      fontSize: `${props.theme!.fontSizes[2]}px`,
      height: 42,
    };
  } else {
    return {
      fontSize: `${props.theme!.fontSizes[1]}px`,
      height: INPUT_HEIGHT,
    };
  }
};

const StyledInput = styled.input<InputProps>`
  ${space} 
  ${flex}
  ${layout}
  ${inputStyles}
  ${sizeStyles}
  ${bordersStyle}
`;

const InputIconContainer = styled.div<InputProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  min-width: 30px;
  height: ${INPUT_HEIGHT}px;

  position: absolute;
  top: 0;
  z-index: 1;

  ${props =>
    props.prefix &&
    css`
      left: 0;
    `}
  ${props =>
    props.suffix &&
    css`
      right: 0;
    `}
`;

const InputBase: React.FC<InputProps> = ({
  error,
  prefix,
  suffix,
  isSearch,
  onPressEnter,
  forwardedRef,
  size,
  ...textInputProps
}) => {
  const handleKeyDownChange = (event: KeyboardEvent<HTMLInputElement>) => {
    if (onPressEnter && event.which === 13) {
      onPressEnter(event);
    }
  };

  return (
    <>
      <Relative>
        {prefix && (
          <InputIconContainer prefix={prefix}>{prefix}</InputIconContainer>
        )}
        <StyledInput
          // @ts-ignore
          size={size}
          pl={prefix ? '30px' : '8px'}
          pr={suffix ? '30px' : '8px'}
          onKeyDown={handleKeyDownChange}
          error={error}
          {...textInputProps}
          ref={forwardedRef}
        />
        {suffix && (
          <InputIconContainer suffix={true}>{suffix}</InputIconContainer>
        )}
        {error && (
          <Typography color="red" mt={2}>
            {error}
          </Typography>
        )}
      </Relative>
    </>
  );
};

InputBase.defaultProps = {
  type: 'text',
  size: 'medium',
};

const Input: React.FC<InputProps> = React.forwardRef((props, ref) => (
  <InputBase {...props} forwardedRef={ref} />
));

Input.displayName = 'Input';

export default Input;
