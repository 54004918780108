import styled, { CSSProp } from 'styled-components';
import {
  borderRadius,
  BorderRadiusProps,
  layout,
  LayoutProps,
  space,
  SpaceProps,
} from 'styled-system';

const Image = styled.img<
  BorderRadiusProps & SpaceProps & LayoutProps & { css?: CSSProp }
>`
  display: block;
  max-width: 100%;
  height: auto;
  
  ${borderRadius}
  ${space}
  ${layout}
`;

Image.displayName = 'Image';

export default Image;
