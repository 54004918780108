import {
  Box,
  Button,
  Divider,
  Flex,
  Hide,
  Icon,
  Link,
  LogoSmall,
  Typography,
} from '@thehhub/core-components';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import styled, { withTheme } from 'styled-components';

const getMinWidth = (px: string) => parseInt(px, 10);

const mqMin = (size: number, css: string) => (props: any) => `
  @media screen and (min-width: ${getMinWidth(
    props.theme && props.theme.breakpoints[size]
  )}px) {
${css}
}`;

const NavLinkText = styled(Typography)`
  //opacity: 0;
`;

const NavLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;

  color: white;
  line-height: 2;
  font-size: 14px;
  line-height: 1;

  opacity: 0.8;
  transition: opacity 0.5s ease;

  padding: 12px 0 13px 0;
  margin: 4px 0 8px;

  &:hover,
  &.active {
    color: white;
    opacity: 1;
    text-decoration: none;
  }

  ${mqMin(
    3,
    `
  justify-content: flex-start;
  padding: 12px 0 13px 25px;
  `
  )}
`;

const SeconaryNavLink = styled(NavLink)`
  opacity: 0.6;
  &:hover {
    opacity: 0.8;
  }
`;

const NavIcon = styled(Icon)`
  width: 1em;
  height: 1em;
  font-size: 15px;
  margin-right: 0;

  ${mqMin(
    3,
    `
    margin-right: 10px;
  `
  )}
`;

interface GlobalNavProps {
  urlPathname: string;
  userType?: 'Client' | 'Creator' | 'Company';
  onSignOut?: () => void;
  theme?: any;
  hasAdminAccess?: boolean;
  hasJobNotifications?: boolean;
  hasMessageNotifications?: boolean;
}

interface Route {
  title: string;
  href: string;
  icon: string;
  useRouter?: boolean;
  target?: string;
}

const GlobalNav = ({
  urlPathname,
  userType,
  onSignOut,
  theme,
  hasAdminAccess,
  hasJobNotifications,
  hasMessageNotifications,
}: GlobalNavProps) => {
  const isAdminRoute = urlPathname === '/admin';
  const adminRoute: Route[] = hasAdminAccess
    ? [
        {
          title: 'Admin',
          href: '/admin',
          icon: 'Lock',
          useRouter: false,
        },
      ]
    : [];

  const commonItems: Route[] = [
    {
      title: 'How it Works',
      icon: 'Lightbulb',
      href: 'https://knowledge.thehhub.com',
      target: '_blank',
    },
    ...adminRoute,

    {
      title: 'My Settings',
      href: '/app/settings',
      icon: 'Settings',
    },
  ];
  const isClient = userType === 'Client';

  const navItems: Route[] = isClient
    ? [
        // {
        //   title: 'Dashboard',
        //   href: '/app',
        //   icon: 'Dashboard',
        // },
        {
          title: 'My Jobs',
          href: '/app/my-jobs',
          icon: 'Briefcase',
        },
        {
          title: 'Messages',
          href: '/app/messages',
          icon: 'Chat',
        },
        {
          title: 'Hire Creators',
          href: '/app/hire-creators',
          icon: 'Search',
        },
      ]
    : [
        // {
        //   title: 'Dashboard',
        //   href: '/app',
        //   icon: 'Dashboard',
        // },
        {
          title: 'Job Board',
          href: '/app/job-board',
          icon: 'StarSqaure',
        },
        {
          title: 'My Jobs',
          href: '/app/my-jobs',
          icon: 'Briefcase',
        },
        {
          title: 'Messages',
          href: '/app/messages',
          icon: 'Chat',
        },
        {
          title: 'Find Creators',
          href: '/app/search',
          icon: 'Search',
        },
        {
          title: 'Bookmarks',
          href: '/app/bookmarks',
          icon: 'Bookmark',
        },
        {
          title: 'My Profile',
          href: '/app/my-profile',
          icon: 'UserCard',
        },
      ];

  return (
    <Box as="nav" bg="black" maxWidth="200px">
      {navItems.map(navItem => {
        const active = urlPathname === navItem.href;
        return (
          <NavLink
            key={navItem.href}
            className={active ? 'active' : ''}
            // as={RouterLink}
            to={navItem.href}
            {...(!isAdminRoute && navItem.href !== '/admin'
              ? {
                  as: RouterLink,
                }
              : {})}
            href={navItem.href}
            title={navItem.title}
            target={navItem.target}
            opacity={active ? 1 : 0.8}
            background={active ? theme.colors.blue : 'transparent'}
          >
            <NavIcon name={navItem.icon} />
            <NavLinkText as={Hide} xs sm md lg>
              {navItem.title}
            </NavLinkText>
            {((navItem.title === 'My Jobs' && hasJobNotifications) ||
              (navItem.title === 'Messages' && hasMessageNotifications)) && (
              <Box
                ml="10px"
                width="5px"
                height="5px"
                borderRadius="50%"
                bg="red"
              />
            )}
          </NavLink>
        );
      })}
      <Divider borderColor="rgba(255,255,255,0.1)" />
      {commonItems.map(navItem => {
        const active = urlPathname === navItem.href;
        return (
          <SeconaryNavLink
            key={navItem.href}
            className={active ? 'active' : ''}
            // as={RouterLink}
            to={navItem.href}
            {...(!isAdminRoute &&
            navItem.href !== '/admin' &&
            !navItem.href.startsWith('http')
              ? {
                  as: RouterLink,
                }
              : {})}
            href={navItem.href}
            target={navItem.target}
            title={navItem.title}
            opacity={active ? 1 : 0.8}
            background={active ? theme.colors.blue : 'transparent'}
          >
            <NavIcon name={navItem.icon} />
            <NavLinkText as={Hide} xs sm md lg>
              {navItem.title}
            </NavLinkText>
          </SeconaryNavLink>
        );
      })}
      <SeconaryNavLink
        px={3}
        href="/app/sign-out"
        onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
          e.preventDefault();
          if (onSignOut) {
            onSignOut();
          }
        }}
      >
        <NavIcon name="SignOut" />
        <NavLinkText as={Hide} xs sm md lg>
          Sign Out
        </NavLinkText>
      </SeconaryNavLink>

      {isClient && (
        <Box mt={4} mb={4} textAlign="center">
          <Button
            as={RouterLink}
            to="/app/post-a-job"
            variant="ghost"
            type="button"
            href="/app/post-a-job"
          >
            Post a job
          </Button>
        </Box>
      )}

      <Flex centered mt={4} flexDirection="column" mb={2}>
        <LogoSmall width={40} height={40} />
        <Hide xs sm md lg>
          <Typography
            textAlign="center"
            color="grayStrong"
            fontSize={0}
            mt={'12px'}
            lineHeight="15px"
          >
            &copy; H Influencer Collective. <br /> All Rights Reserved.
          </Typography>
        </Hide>
      </Flex>
    </Box>
  );
};

export default withTheme(GlobalNav);
