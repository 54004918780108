import * as React from 'react';
import {
  Button,
  Grid,
  Absolute,
  Box,
  Icon,
  Relative,
} from '@thehhub/core-components';
import { buildCloudinaryImageUrl } from '@thehhub/cloudinary-utils';

const { Item } = Grid;

const ImageUploadCloseButton = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
) => (
  <svg width={17} height={17} fill="none" {...props}>
    <circle
      cx={8.5}
      cy={8.5}
      r={7.5}
      fill="#161616"
      fillOpacity={0.3}
      stroke="#FEFEFE"
    />

    <path
      d="M5.555 5.554l5.892 5.892M11.447 5.554l-5.892 5.892"
      stroke="#FEFEFE"
      strokeLinecap="round"
    />
  </svg>
);

export interface ImageUploadGridProps {
  images: any[];
  onRemove?: any;
  size?: number;
  gap?: number;
  height?: number | string;
}

const ImageUploaderGrid: React.FC<ImageUploadGridProps> = ({
  images,
  onRemove,
  size = 72,
  gap = 9,
  height = 245,
}) => (
  <Box mb={3} overflow="auto" height={['100%', '100%', height]}>
    <Grid
      gridTemplateColumns={`repeat(auto-fit,minmax(${size}px, ${size}px))`}
      gridGap={`${gap}px`}
    >
      {images.map((mediaItem, i) => (
        <Item key={i}>
          <Relative
            width={`${size}px`}
            height={`${size}px`}
            overflow="hidden"
            borderRadius={2}
          >
            <Absolute right="4px" top="-2px" zIndex={1}>
              <Button variant="text" type="button" onClick={onRemove(i)}>
                <ImageUploadCloseButton />
              </Button>
            </Absolute>
            <Absolute top={24} left={24}>
              <Icon name="Spinner" size={24} color="blue" />
            </Absolute>
            <Relative>
              {mediaItem.publicId && (
                <img
                  key={i}
                  src={buildCloudinaryImageUrl(
                    mediaItem.publicId,
                    `w_${size},h_${size},c_fill`
                  )}
                  alt={mediaItem.publicId}
                />
              )}
            </Relative>
          </Relative>
        </Item>
      ))}
    </Grid>
  </Box>
);

export default ImageUploaderGrid;
