import {
  Avatar,
  Box,
  Button,
  Flex,
  Hide,
  Icon,
  Logo,
  Typography,
} from '@thehhub/core-components';
import React from 'react';
import { animated } from 'react-spring';
import styled, { css } from 'styled-components';
import GlobalNav from '../global-nav';

export const SIDER_WIDTH_TABLET = 80;
export const SIDER_WIDTH_DESKTOP = 200;
export const HEADER_HEIGHT = 60;

const getMinWidth = (px: string) => parseInt(px, 10);

const mqMin = (size: number, css: string) => (props: any) => `
  @media screen and (min-width: ${getMinWidth(
    props.theme && props.theme.breakpoints[size]
  )}px) {
${css}
}`;

const AnimatedBox = styled(Box)`
  will-change: transform, width, padding;
  transition: transform ${props => props.theme.duration.fast} ease,
    width ${props => props.theme.duration.fast} ease,
    padding ${props => props.theme.duration.fast} ease;
`;

const Main = styled(AnimatedBox)`
  padding-top: ${HEADER_HEIGHT}px;
  ${mqMin(
    2,
    `
  padding-top: 0;
  padding-left: ${SIDER_WIDTH_TABLET}px;
  `
  )}
  ${mqMin(
    3,
    `
  padding-top: 0;
  padding-left: ${SIDER_WIDTH_DESKTOP}px;
  `
  )}
`;

const Sider = styled(AnimatedBox)`
  transform: translate3d(-100%, 0, 0);
  overflow: hidden;
  overflow-y: auto;

  ${mqMin(
    2,
    `
  width: ${SIDER_WIDTH_TABLET}px;
  transform: translate3d(0, 0, 0)
  `
  )}
  ${mqMin(
    3,
    `
  width: ${SIDER_WIDTH_DESKTOP}px;
  transform: translate3d(0, 0, 0)
  `
  )}
`;

const Header = styled(AnimatedBox)`
  overflow: hidden;
  transition-delay: 0ms;
  transform: translate3d(0, 0, 0);
  ${mqMin(2, `transform: translate3d(0, -100%, 0)`)}
`;

interface AppLayoutProps {
  theme?: any;
  urlPathname: string;
  userType: 'Client' | 'Creator';
  onSignOut?: () => void;
  AvatarComponent?: any;
  hasAdminAccess?: boolean;
  hasJobNotifications?: boolean;
  hasMessageNotifications?: boolean;
  userAvatarSrc?: string;
  userName?: string;
  userTagline?: string;
  userVerified?: boolean;
}

const AppLayout: React.FC<AppLayoutProps> = ({
  children,
  urlPathname,
  userType,
  hasAdminAccess,
  hasJobNotifications,
  hasMessageNotifications,
  onSignOut,
  userAvatarSrc,
  userName,
  userTagline,
  userVerified,
}) => {
  if (!userType) {
    return <>{children}</>;
  }

  return (
    <>
      <Header
        as="header"
        bg="black"
        position="fixed"
        top="0"
        left="0"
        width={1}
        zIndex={4}
      >
        <Flex alignItems="center" height="100%" justifyContent="space-between">
          <Button variant="text" px={3} color="white">
            <Icon name="Hamburger" />
          </Button>
          <Box
            as={Logo}
            width={72}
            css={css`
              min-width: 72px;
            `}
          />
          <Button
            variant="text"
            px={3}
            color="white"
            style={{ opacity: 0, pointerEvents: 'none' }}
          >
            <Icon name="Bell" />
          </Button>
        </Flex>
      </Header>
      <Main position="relative" zIndex={2} as={animated.main}>
        {children}
      </Main>
      <Sider
        as={animated.aside}
        bg="black"
        position="fixed"
        top={0}
        left={0}
        height="100vh"
        zIndex={3}
        width={SIDER_WIDTH_DESKTOP}
      >
        <Box>
          <Flex centered minHeight="120px">
            <Flex
              alignItems="center"
              justifyContent="center"
              flex={1}
              flexDirection="column"
              py="15px"
              pb="10px"
            >
              <Hide xl xxl>
                <Avatar src={userAvatarSrc} size={60} verified={userVerified} />
              </Hide>
              <Hide xs sm md lg>
                <Avatar src={userAvatarSrc} size={72} verified={userVerified} />
              </Hide>
              <Hide xs sm md lg>
                <Typography
                  textAlign="center"
                  pt={2}
                  color="white"
                  bold
                  opacity={0.8}
                >
                  {userName}
                </Typography>
                <Typography
                  textAlign="center"
                  opacity={0.8}
                  caps
                  bold
                  color="grayStrong"
                  fontSize={0}
                >
                  {userTagline}
                </Typography>
              </Hide>
            </Flex>
          </Flex>
          <GlobalNav
            hasAdminAccess={hasAdminAccess}
            hasMessageNotifications={hasMessageNotifications}
            hasJobNotifications={hasJobNotifications}
            urlPathname={urlPathname}
            userType={userType}
            onSignOut={onSignOut}
          />
        </Box>
      </Sider>
    </>
  );
};

export default AppLayout;
