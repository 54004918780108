import React, { ChangeEvent, forwardRef, useState } from 'react';
import styled from 'styled-components';
import {
  Box,
  Button,
  Flex,
  FlexInline,
  Icon,
  Input,
  Typography,
} from '@thehhub/core-components';

export type InputListValue = string[];

const StyledInput = styled(Input)`
  border-radius: 5px 0 0 5px;
  flex: 1;
  border-right: 0;
  padding-left: 8px;
  > div {
    height: auto;
  }
`;
const StyledButton = styled(Button)`
  min-width: 35px;
  width: 81px;
  height: 42px;
  padding: 0;
  margin: 0;
  border-radius: 0 5px 5px 0px;
`;

const AddIcon = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
) => (
  <svg width={15} height={15} fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 7.5a7.5 7.5 0 11-15 0 7.5 7.5 0 0115 0zM7.5 2.91a.5.5 0 01.5.5V7h3.591a.5.5 0 010 1h-3.59v3.59a.5.5 0 01-1 0V8H3.408a.5.5 0 010-1H7V3.41a.5.5 0 01.5-.5z"
      fill="#FEFEFE"
    />
  </svg>
);

export interface InputListProps {
  placeholder?: string;
  onChange?: (entries: InputListValue) => void;
  value?: InputListValue;
  forwardedRef?: React.Ref<any>;
}

const InputListFC: React.FC<InputListProps> = React.memo(
  ({ placeholder, onChange, value, forwardedRef }) => {
    // decouple list from entries so full render doesnt fire on every change
    const [list, setList] = useState<InputListValue>(value || []);
    const [entries, setEntries] = useState<InputListValue>(value || []);
    const [entry, setEntry] = useState<string>('');

    const handleAdd = () => {
      if (!entry) {
        return;
      }
      const next = entries.concat([entry]);
      setList(next);
      setEntries(next);
      if (onChange) {
        onChange(next);
      }
      setEntry('');
    };

    const handleRemove = (index: number) => () => {
      const next = entries.filter((_item, i) => i !== index);
      setList(next);
      setEntries(next);
      if (onChange) {
        onChange(next);
      }
    };

    const handleEntryChange = (e: ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      setEntry(value);

      if (onChange) {
        onChange([value]);
      }
    };

    const handleEntriesChange = (index: number) => (
      e: ChangeEvent<HTMLInputElement>
    ) => {
      const entryValue = e.target.value;
      setEntries(prev => {
        const next = [...prev];
        next[index] = entryValue;
        return next;
      });
    };

    return (
      <>
        {list.map((_item, index) => (
          <Flex key={index} mb={3}>
            <Box flex={1}>
              <StyledInput
                size="large"
                value={entries[index]}
                onChange={handleEntriesChange(index)}
                disabled
              />
            </Box>
            <StyledButton
              variant="danger"
              type="button"
              color="red"
              // icon="delete"
              onClick={handleRemove(index)}
            >
              {/*<Relative top="-2px">*/}
              {/*  <Typography color="blue" bold fontSize="22px" lineHeight="100%">*/}
              {/*    -*/}
              {/*  </Typography>*/}
              {/*</Relative>*/}
              <FlexInline alignItems="center">
                <Icon name="Close" size={12} color="red" />
                <Typography ml={1} as="span" fontSize={0} caps bold color="red">
                  Remove
                </Typography>
              </FlexInline>
            </StyledButton>
          </Flex>
        ))}
        <Flex>
          <Box flex={1}>
            <StyledInput
              flex={1}
              mr={3}
              placeholder={placeholder}
              value={entry}
              onChange={handleEntryChange}
              ref={forwardedRef}
              onPressEnter={(e: { preventDefault: () => void }) => {
                e.preventDefault();
                handleAdd();
              }}
              size="large"
            />
          </Box>

          <StyledButton
            ml={3}
            type="button"
            disabled={!entry}
            // icon="plus"
            onClick={handleAdd}
            // css={{
            //   minWidth: '35px',
            //   // width: 35,
            //   width: 81,
            //   height: 42,
            //   padding: 0,
            //   borderRadius: '0 5px 5px 0px',
            // }}
            // shape="circle"
          >
            {/*<Relative as="span" top="-2px">*/}
            {/*  <Typography as="span" bold fontSize="22px" lineHeight="100%">*/}
            {/*    +*/}
            {/*  </Typography>*/}
            {/*</Relative>*/}
            <FlexInline alignItems="center">
              <AddIcon />
              <Typography ml={2} as="span">
                Add
              </Typography>
            </FlexInline>
          </StyledButton>
        </Flex>
      </>
    );
  }
);

// export default InputList;

const InputList: React.FC<InputListProps & any> = forwardRef((props, ref) => (
  <InputListFC {...props} forwardedRef={ref} />
));

export default InputList;
