import { notification } from 'antd';
import ImageUploaderGrid from './ImageUploaderGrid';
import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Button, Flex, Icon, Typography } from '@thehhub/core-components';
import { uploadImage } from '@thehhub/cloudinary-utils';

const logger = console;

const ImageUploader = ({
  onChange,
  value,
  title = 'Drag and drop files',
  description = 'or click to select files',
  uploadLabel = 'Upload',
  height = '242px',
  imageGridProps = {
    size: 72,
    gap: 9,
    height: '242px',
  },
}: any) => {
  const [images, setImages] = useState<any[]>(value || []);

  const onDrop = useCallback(
    (acceptedFiles: any[]) => {
      setImages(prev =>
        prev.concat(
          acceptedFiles.map(() => ({
            loading: true,
          }))
        )
      );
      logger.debug(acceptedFiles);
      Promise.all(acceptedFiles.map(file => uploadImage(file))).then(
        results => {
          setImages(prev => {
            const updated = prev
              .filter(image => !image.loading)
              .concat(
                results.map((result: any) => ({
                  publicId: result.public_id,
                  type: 'IMAGE',
                }))
              );
            onChange(updated);
            return updated;
          });
        }
      );
    },
    [onChange]
  );

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDropRejected: () => {
      notification.error({ message: 'You can only upload images!' });
    },
    onDrop,
  });

  if (images.length > 0) {
    return (
      <>
        <ImageUploaderGrid
          images={images}
          onRemove={(i: number) => () => {
            setImages(prev => {
              const updated = prev.filter((_image, index) => i !== index);
              onChange(updated);
              return updated;
            });
          }}
          height={height}
          {...imageGridProps}
        />
        <Button
          type="button"
          disabled={images.filter(image => image.loading).length > 0}
          data-testid="dropzone"
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          Add More
        </Button>
      </>
    );
  }

  return (
    <Flex
      {...getRootProps()}
      data-testid="dropzone"
      css={{ cursor: 'pointer' }}
      alignItems="center"
      justifyContent="center"
      height={['100%', '100%', '245px']}
      mb={3}
      borderRadius={2}
      borderStyle="dashed"
      borderWidth="1px"
      borderColor="grayStrong"
    >
      <input {...getInputProps()} />
      <Typography textAlign="center">
        <Icon name="PlusCircle" color="blue" size={48} />

        <Typography my={3}>
          <Typography color="text" m={0} fontSize={4} bold>
            {title}
          </Typography>
          <Typography color="text" m={0} fontSize={2}>
            {description}
          </Typography>
        </Typography>
        <Button type="button">{uploadLabel}</Button>
      </Typography>
    </Flex>
  );
};

export default ImageUploader;
