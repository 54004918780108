import React, { FunctionComponent } from 'react';
import Icon from '../icon';
import Flex from '../flex';
import Button from '../button';
import Typography from '../typography';

const IconButton: FunctionComponent<any> = ({
  iconProps,
  children,
  ...props
}) => {
  const icon = <Icon {...iconProps} />;

  return (
    <Button {...props}>
      {children ? (
        <Flex>
          {icon}
          <Typography pl={1}>{children}</Typography>
        </Flex>
      ) : (
        icon
      )}
    </Button>
  );
};

IconButton.displayName = 'IconButton';

export default IconButton;
