import React, { FC, ReactNode } from 'react';
import Box, { BoxProps } from '../box/Box';

interface CardProps extends BoxProps {
  footer?: ReactNode | null;
}

const Card: FC<CardProps> = ({ children, footer, color, ...rest }) => {
  return (
    <Box
      bg="white"
      borderRadius={2}
      border="default"
      boxShadow="card"
      {...rest}
    >
      <div>
        {children}
        {footer ? footer : null}
      </div>
    </Box>
  );
};

export default Card;
