import React, { FC } from 'react';
import {
  Absolute,
  Avatar,
  Box,
  Flex,
  Rating,
  Relative,
} from '@thehhub/core-components';
import { buildCloudinaryImageUrl } from '@thehhub/cloudinary-utils';

function determineRatingPercent(
  ratingPercent: number,
  verified: boolean,
  risingStar: boolean
) {
  if (!ratingPercent) {
    ratingPercent = 0;
  }
  const delta = 0.15;
  if (verified) {
    ratingPercent = 1;
  } else if (risingStar && ratingPercent < 0.8) {
    ratingPercent = 0.8;
  } else if (risingStar && ratingPercent > 0.8) {
    ratingPercent = ratingPercent + delta;
    if (ratingPercent > 1) {
      ratingPercent = 1;
    }
  } else if (ratingPercent < 0.8) {
    if (delta + ratingPercent > 0.8) {
      ratingPercent = 0.8;
    } else {
      ratingPercent = ratingPercent + delta;
    }
  }
  return ratingPercent;
}

/**
 * avatar with rating and Featured Check
 *
 * @param verified
 * @param risingStar
 * @param avatarPublicId
 * @param isLazy
 * @param width
 * @param verifiedIconProps
 * @param starSize
 * @param verifiedBadgeSize
 * @param ratingPercent
 * @param rest
 * @constructor
 */
const AvatarWithRating: FC<{
  verified?: boolean;
  risingStar?: boolean;
  avatarPublicId: string;
  isLazy?: boolean;
  width?: any;
  verifiedIconProps?: any;
  starSize?: any;
  verifiedBadgeSize?: any;
  ratingPercent?: any;
  [p: string]: any;
}> = ({
  verified,
  risingStar,
  avatarPublicId,
  isLazy,
  width = 72,
  verifiedIconProps,
  starSize,
  verifiedBadgeSize,
  ...rest
}) => {
  // if (verified) {
  //   if (!ratingPercent || ratingPercent * 100 < 80) {
  //     ratingPercent = 0.8;
  //   }
  // }
  let { ratingPercent } = rest;
  const renderRating = () => {
    ratingPercent = determineRatingPercent(
      ratingPercent,
      !!verified,
      !!risingStar
    );
    return (
      typeof ratingPercent !== 'undefined' && (
        <Box minHeight="15px">
          <Rating
            data-testid="avatar-rating"
            percent={ratingPercent}
            size={starSize}
          />
        </Box>
      )
    );
  };

  return avatarPublicId ? (
    <Flex
      alignItems="center"
      flexDirection="column"
      {...rest}
      style={{
        cursor: rest.onClick ? 'pointer' : 'default',
      }}
    >
      <Relative>
        {verified && (
          <Absolute top="0" right="0">
            <img
              src="/static/HVerified.png"
              alt="H Verified"
              width={verifiedBadgeSize || 30}
              height={verifiedBadgeSize || 30}
            />
            {/*<HVerifiedCheck {...verifiedIconProps} />*/}
          </Absolute>
        )}
        <Box mb={2}>
          <Avatar
            // isLazy={!!isLazy}
            src={buildCloudinaryImageUrl(avatarPublicId, `w_${width * 2}`)}
            size={width}
          />
        </Box>
      </Relative>
      {renderRating()}
    </Flex>
  ) : null;
};

export default AvatarWithRating;
