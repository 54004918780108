import get from 'lodash.get';
import React, { useState } from 'react';
import {
  Box,
  Button,
  Flex,
  Image,
  Relative,
  Typography,
} from '@thehhub/core-components';
import { buildCloudinaryImageUrl } from '@thehhub/cloudinary-utils';
import ImageUploader from '../image-uploader';

const MoodBoardStep = ({
  // getFieldDecorator,
  // form,
  // currentStep,
  payload,
  // jobStrength,
  // handleNext,
  toggleFooter,
  setPayload,
  textAlign,
  onChange,
}: any) => {
  const samples = [
    'post-a-job/moodboard-samples/1',
    'post-a-job/moodboard-samples/2',
    'post-a-job/moodboard-samples/3',
    'post-a-job/moodboard-samples/4',
    'post-a-job/moodboard-samples/5',
    'post-a-job/moodboard-samples/6',
  ];
  const [showSample, setShowSample] = useState(false);
  // const [images, setImages] = useState(value);
  // const [uploadingIndex, setUploading] = useState(null);
  const handleSampleViewToggle = () => {
    if (toggleFooter) {
      toggleFooter();
    }
    setShowSample(prev => !prev);
  };
  if (showSample) {
    return (
      <>
        <Flex flexWrap="wrap" alignItems="center" pb={4}>
          {showSample &&
            samples.map((image, i) => (
              <Relative width={1 / 3} key={i} mb={2}>
                {image ? (
                  <Relative>
                    <Flex alignItems="center" justifyContent="center">
                      <Image
                        borderRadius={2}
                        width={164}
                        height={162}
                        src={buildCloudinaryImageUrl(
                          image,
                          'w_164,h_162,c_fill'
                        )}
                      />
                    </Flex>
                  </Relative>
                ) : null}
              </Relative>
            ))}
        </Flex>

        <Flex alignItems="center" justifyContent="center">
          <Button type="button" onClick={handleSampleViewToggle}>
            Close Sample
          </Button>
        </Flex>
      </>
    );
  }

  return (
    <Box px={3}>
      <Typography textAlign={textAlign || 'center'} mb={4}>
        {get(payload, 'details.moodBoard', []).length > 0 && (
          <Typography fontSize="18px" bold>
            Mood board upload
          </Typography>
        )}

        {/*<Typography mt={2} mb={3} fontSize="16px" color="#6F6F6F">*/}
        {/*  Add inspirational images to show your vision to your creator!*/}
        {/*</Typography>*/}
        <Button
          type="button"
          variant="text"
          color="blue"
          onClick={handleSampleViewToggle}
        >
          Show a sample
        </Button>
      </Typography>
      <ImageUploader
        height="100%"
        imageGridProps={{
          size: 100,
          gap: 12,
          height: '100%',
        }}
        uploadLabel="Select Images"
        title="Mood board upload"
        description="Add inspirational images to show your vision to your creator!"
        value={get(payload, 'details.moodBoard', [])}
        onChange={(files: any) => {
          console.log(files);
          setPayload((prev: { details: any }) => {
            const merged = {
              ...prev,
              details: {
                ...(prev?.details ?? {}),
                moodBoard: files,
              },
            };
            // const merged = merge(prev, { details: { moodBoard: files } });
            if (onChange) {
              onChange(merged);
            }
            return merged;
          });
          // setPayload
        }}
      />
    </Box>
  );
  // const empty = [null, null, null, null, null, null, null, null];
  // console.log(get(payload, 'details.moodBoard'));
  // let defaultState =
  //   (get(payload, 'details.moodBoard') || empty).map(item => {
  //     if (!item) {
  //       return null;
  //     }
  //     if (typeof item === 'string') {
  //       return item;
  //     }
  //     if (item.publicId) {
  //       return item.publicId;
  //     }
  //     return item;
  //   }) || empty;
  // if (defaultState.length === 0) {
  //   defaultState = empty;
  // } else {
  //   const extra = empty.splice(
  //     defaultState.length,
  //     empty.length - defaultState.length
  //   );
  //   defaultState = defaultState.concat(extra);
  // }
  //
  // const [showSample, setShowSample] = useState(false);
  // const [images, setImages] = useState(defaultState);
  // const [uploadingIndex, setUploading] = useState(null);
  //
  // const handleSampleViewToggle = () => {
  //   if (toggleFooter) {
  //     toggleFooter();
  //   }
  //   setShowSample(prev => !prev);
  // };
  //
  // const preloadHolderId = 'preload-image';
  // const handleFileSelect = index => async e => {
  //   setUploading(index);
  //   const [file] = e.target.files;
  //   if (!file) {
  //     return;
  //   }
  //   const cloudinaryResponse = await handleCloudinaryUpload(file);
  //   if (!cloudinaryResponse) {
  //     return;
  //   }
  //   const width = 90;
  //
  //   const existingHolderImage: any = document.getElementById(preloadHolderId);
  //   const newSource = buildCloudinaryImageUrl(
  //     cloudinaryResponse.public_id,
  //     `w_${width! * 2},h_${width! * 2},c_fill`
  //   );
  //   const handleImageLoad = () => {
  //     setUploading(null);
  //     setImages(images => {
  //       const newImages = [...images];
  //       newImages[index] = cloudinaryResponse.public_id;
  //       setPayload(prev => {
  //         const merged = merge(prev, { details: { moodBoard: newImages } });
  //         if (onChange) {
  //           onChange(merged);
  //         }
  //         return merged;
  //       });
  //       return newImages;
  //     });
  //   };
  //
  //   if (existingHolderImage) {
  //     existingHolderImage.src = newSource;
  //     existingHolderImage.onload = handleImageLoad;
  //   } else {
  //     // @ts-ignore
  //     const image: any = new window.Image(width, width);
  //     image.src = newSource;
  //     image.style.display = 'none';
  //     image.id = preloadHolderId;
  //     image.onload = handleImageLoad;
  //     document.body.appendChild(image);
  //   }
  // };
  //
  // const handleRemove = index => () => {
  //   setImages(images => {
  //     const newImages = [...images];
  //     newImages[index] = null;
  //     setPayload(prev => {
  //       const merged = merge(prev, {
  //         details: {
  //           moodBoard:
  //             newImages.filter(i => i !== null).length === 0 ? null : newImages,
  //         },
  //       });
  //       if (onChange) {
  //         onChange(merged);
  //       }
  //       return merged;
  //     });
  //     return newImages;
  //   });
  // };
  //
  // return (
  //   // @ts-ignore
  //   <Container px={textAlign === 'left' ? 0 : 4}>
  //     {!showSample && (
  //       <Typography textAlign={textAlign || 'center'} mb={4}>
  //         <Typography fontSize="18px" bold>
  //           Mood board upload
  //         </Typography>
  //         <Typography mt={2} mb={3} fontSize="16px" color="#6F6F6F">
  //           Add inspirational images to show your vision to your creator!
  //         </Typography>
  //         <TextButton color="blue" onClick={handleSampleViewToggle}>
  //           Show a sample
  //         </TextButton>
  //       </Typography>
  //     )}
  //
  //     <Flex flexWrap="wrap" alignItems="center" pb={4}>
  //       {showSample
  //         ? samples.map((image, i) => (
  //             <Relative width={1 / 3} key={i} mb={2}>
  //               {image ? (
  //                 <Relative>
  //                   <Flex alignItems="center" justifyContent="center">
  //                     <Image
  //                       borderRadius={2}
  //                       width={164}
  //                       height={162}
  //                       src={buildCloudinaryImageUrl(
  //                         image,
  //                         'w_164,h_162,c_fill'
  //                       )}
  //                     />
  //                   </Flex>
  //                 </Relative>
  //               ) : null}
  //             </Relative>
  //           ))
  //         : images.map((image, i) => (
  //             <Relative width={1 / 4} key={i} mb={2}>
  //               {image ? (
  //                 <Relative>
  //                   <Flex alignItems="center" justifyContent="center">
  //                     <Absolute top={0} right={'50%'} mr="-50px">
  //                       <CloseButton color="white" onClick={handleRemove(i)} />
  //                     </Absolute>
  //                     <Image
  //                       borderRadius={2}
  //                       width={100}
  //                       height={90}
  //                       src={buildCloudinaryImageUrl(
  //                         image,
  //                         'w_100,h_90,c_fill'
  //                       )}
  //                     />
  //                   </Flex>
  //                 </Relative>
  //               ) : (
  //                 <Flex
  //                   height="90px"
  //                   alignItems="center"
  //                   justifyContent="center"
  //                 >
  //                   <StyledFileInput
  //                     type="file"
  //                     accept="image/*"
  //                     onChange={handleFileSelect(i)}
  //                   />
  //                   <Typography textAlign="center">
  //                     {uploadingIndex === i ? (
  //                       <Icon name="Spinner" color="blue" size={24} />
  //                     ) : (
  //                       <MoodBoardUploadButton />
  //                     )}
  //                   </Typography>
  //                 </Flex>
  //               )}
  //             </Relative>
  //           ))}
  //     </Flex>
  //
  //     {showSample && (
  //       <Flex alignItems="center" justifyContent="center">
  //         <Button onClick={handleSampleViewToggle}>Close Sample</Button>
  //       </Flex>
  //     )}
  //   </Container>
  // );
};

export default MoodBoardStep;
