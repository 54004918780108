import styled from 'styled-components';
import { layout, LayoutProps, space, SpaceProps } from 'styled-system';

interface ContainerPropsBase {
  maxWidth?: number;
  theme?: any;
}

type ContainerProps = ContainerPropsBase & LayoutProps & SpaceProps;

const maxWidth = (props: ContainerProps) =>
  props.maxWidth
    ? { maxWidth: `${props.maxWidth}px` }
    : { maxWidth: props.theme && props.theme!.maxContainerWidth };

const Container = styled.div<ContainerProps>`
  margin-left: auto;
  margin-right: auto;

  ${space}
  ${layout}
  ${maxWidth};
`;

Container.displayName = 'Container';

export default Container;
