import { Form, InputNumber } from 'antd';
import React, { useState } from 'react';
import { Box, Flex } from '@thehhub/core-components';

const formatter = (value: any) =>
  `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

const BudgetRangeInputs = ({ value, onChange }: any) => {
  const range = value;
  const [min, setMin] = useState<any>(range?.min ?? null);
  const [max, setMax] = useState<any>(range?.max ?? null);

  return (
    <Flex alignItems="center">
      <Form.Item style={{ margin: 0 }}>
        <InputNumber
          size="large"
          min={250}
          max={10000}
          style={{ width: '108px' }}
          placeholder="$250"
          formatter={formatter}
          value={min}
          onChange={val => {
            setMin(val);
            onChange({ min: val, max });
          }}
        />
      </Form.Item>
      <Box as="span" px={4}>
        &mdash;
      </Box>
      <Form.Item style={{ margin: 0 }}>
        <InputNumber
          size="large"
          min={min ? min + 1 : 250}
          width={108}
          placeholder="$10,000"
          style={{ width: '108px' }}
          max={10000}
          formatter={formatter}
          value={max}
          onChange={val => {
            setMax(val);
            onChange({ min, max: val });
          }}
        />
      </Form.Item>
    </Flex>
  );
};

export default BudgetRangeInputs;
