import styled from 'styled-components';
import {
  borderColor,
  BorderColorProps,
  space,
  SpaceProps,
  width,
  WidthProps,
} from 'styled-system';

const Divider = styled.hr<SpaceProps & WidthProps & BorderColorProps>`
  margin-left: 0;
  margin-right: 0;
  border: 0;
  border-bottom: 1px solid ${props => props.theme.colors.borderGray};
  ${space} 
  ${width} 
  ${borderColor};
`;

Divider.displayName = 'Divider';

export default Divider;
