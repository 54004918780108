import styled from 'styled-components';
import Typography from '../typography';

const Truncate = styled(Typography)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

Truncate.displayName = 'Truncate';

export default Truncate;
