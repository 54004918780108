import React from 'react';
// @ts-ignore
import * as icons from '@thehhub/web-icons';
import { SpaceProps, ColorProps } from 'styled-system';

export interface IconProps {
  name?: string;
  size?: number;
}

const Icon: React.FC<IconProps & SpaceProps & ColorProps> = ({
  name,
  ...props
}) => {
  // @ts-ignore
  const Component: any = icons[name];
  if (!Component) {
    return null;
  }
  return <Component {...props} />;
};

Icon.defaultProps = {
  size: 24,
};

Icon.displayName = 'Icon';

export default Icon;
