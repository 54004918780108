import { variant } from 'styled-system';
import Box from '../box/Box';
import styled from 'styled-components';

interface GradientBoxProps {
  degree?: string;
  color1?: string;
  color2?: string;
  variant?: 'default' | 'purple';
}

export const gradientBoxThemes = {
  purple: {
    degree: '129.75deg',
    color1: 'rgba(109, 81, 212, 0.5) 51.97%',
    color2: 'rgba(218, 206, 255, 0.5) 96.07%',
  },
  default: {
    degree: '169.37deg',
    color1: 'rgba(160, 193, 146, 1) -28.75%',
    color2: 'rgba(107, 168, 191, 1) 55.73%',
  },
};

const bgImageUrl =
  'https://res.cloudinary.com/dfifzuyto/image/upload/q_auto/graphics/8099dcea57dc06eb25e614ab2c408d2a.jpg';

const GradientBox = styled(Box)<GradientBoxProps>(
  props => ({
    background: `linear-gradient(
      ${props.degree},
      ${props.color1},
      ${props.color2}
    ),
    url(${bgImageUrl})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  }),
  variant({
    variants: {
      purple: {
        background: `linear-gradient(
          ${gradientBoxThemes.purple.degree},
          ${gradientBoxThemes.purple.color1},
          ${gradientBoxThemes.purple.color2}
        ),
        url(${bgImageUrl})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      },
    },
  })
);

GradientBox.defaultProps = gradientBoxThemes.default;

export default GradientBox;
